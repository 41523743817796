@use '@angular/material' as mat;
@import '@angular/material/theming';

/* http://mcg.mbitson.com/#!?rose=%23ee0072&blue=%2300afec&orange=%23fcb731&gray=%23939598&bluedark=%23004157&themename=ubolino#%2F */
$md-rose: (
    50 : #fde0ee,
    100 : #fab3d5,
    200 : #f780b9,
    300 : #f34d9c,
    400 : #f12687,
    500 : #ee0072,
    600 : #ec006a,
    700 : #e9005f,
    800 : #e70055,
    900 : #e20042,
    A100 : #ffffff,
    A200 : #ffd6df,
    A400 : #ffa3b8,
    A700 : #ff8aa4,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-blue: (
    50 : #e0f5fd,
    100 : #b3e7f9,
    200 : #80d7f6,
    300 : #4dc7f2,
    400 : #26bbef,
    500 : #00afec,
    600 : #00a8ea,
    700 : #009fe7,
    800 : #0096e4,
    900 : #0086df,
    A100 : #ffffff,
    A200 : #d3ebff,
    A400 : #a0d4ff,
    A700 : #86c9ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-orange: (
    50 : #fff6e6,
    100 : #fee9c1,
    200 : #fedb98,
    300 : #fdcd6f,
    400 : #fcc250,
    500 : #fcb731,
    600 : #fcb02c,
    700 : #fba725,
    800 : #fb9f1f,
    900 : #fa9013,
    A100 : #ffffff,
    A200 : #fffaf5,
    A400 : #ffe1c2,
    A700 : #ffd4a8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-gray: (
    50 : #f2f2f3,
    100 : #dfdfe0,
    200 : #c9cacc,
    300 : #b3b5b7,
    400 : #a3a5a7,
    500 : #939598,
    600 : #8b8d90,
    700 : #808285,
    800 : #76787b,
    900 : #64676a,
    A100 : #e3f0fc,
    A200 : #b4d6f8,
    A400 : #7abdff,
    A700 : #61b0ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-bluedark: (
    50 : #e0e8eb,
    100 : #b3c6cd,
    200 : #80a0ab,
    300 : #4d7a89,
    400 : #265e70,
    500 : #004157,
    600 : #003b4f,
    700 : #003246,
    800 : #002a3c,
    900 : #001c2c,
    A100 : #65baff,
    A200 : #32a3ff,
    A400 : #008cfe,
    A700 : #007ee5,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);



$mat-white: (
    500: white,
    contrast: (
        500: $black-87-opacity
    )
);

$mat-black: (
    500: black,
    contrast: (
        500: white,
    )
);
//$mat-fusedark origin
/*
$mat-fusedark: (
    50: #ECECEE,
    100: #C5C6CB,
    200: #9EA1A9,
    300: #7D818C,
    400: #5C616F,
    500: #3C4252,
    600: #353A48,
    700: #2D323E,
    800: #262933,
    900: #1E2129,
    A100: #C5C6CB,
    A200: #9EA1A9,
    A400: #5C616F,
    A700: #2D323E,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: white,
        400: white,
        500: $white-87-opacity,
        600: $white-87-opacity,
        700: $white-87-opacity,
        800: $white-87-opacity,
        900: $white-87-opacity,
        A100: $black-87-opacity,
        A200: $white-87-opacity,
        A400: $white-87-opacity,
        A700: $white-87-opacity,
    )
);
*/
//$md-bluedark
$mat-fusedark: (
    50 : #e0f5fd,
    100 : #b3e7f9,
    200 : #80d7f6,
    300 : #4dc7f2,
    400 : #26bbef,
    500 : #00afec,
    600 : #00a8ea,
    700 : #009fe7,
    800 : #0096e4,
    900 : #0086df,
    A100 : #ffffff,
    A200 : #d3ebff,
    A400 : #a0d4ff,
    A700 : #86c9ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Define the Material palettes
$primary: mat.define-palette($mat-fusedark, 500);
$accent: mat.define-palette($md-orange, 500);
$warn: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$theme: mat.define-light-theme($primary, $accent, $warn);

// Store the background and foreground colors for easier access
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

// Force the input field font sizes to 16px
$typography: mat.define-legacy-typography-config(
    $font-family: '"drBuch", "Helvetica Neue", Arial, sans-serif',
    $input: mat.define-typography-level(20px, 1.125, 600, "drBuch", 0.25),
    $title: mat.define-typography-level(32px, 24px, 500, "drBuch")
);

// Temporary fix remove Cookie policy button after accepting them
.cc-revoke{
    display: none !important;
}
@use '@angular/material' as mat;
@import "../../default/_path-function";

.mat-button-ripple.mat-ripple, .mat-button-focus-overlay {
    visibility: hidden !important; // remove default effect of material on click
}

%mat-stroked-button {
    border-radius: 2em;
    font-weight: 700;
    box-sizing: border-box;
    margin-right: 12px;
    padding: 0 50px;
    border: 1px solid currentColor;
    @include mat.elevation(1);

    text-decoration-line: none !important;
    display: flex;
    align-items: center;

}

%mat-stroked-button-primary {
    @extend %mat-stroked-button;
    border: 1px solid map-get($primary, 500);
    color: map-get($primary, 500);
    background-color: map-get($mat-white, 500);
}

%svgFullContent {
    justify-content: center;
    align-items: center;
    box-shadow: none;

    .mat-button-wrapper {
        height: 100%;
        min-height: 100%;
        width: 100%;
    }

    .mat-icon {
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
    }

    svg {
        transform: scale(1.15); // remove svg inner margin
    }
}


button, a {

    font-family: #{$font-family};

    &.mat-raised-button.mat-accent {
        border-radius: 2em;
        font-weight: 700;
    }

    &.mat-fab, &.mat-mini-fab {
        width: auto;
        height: auto;

        &.mat-accent, .mat-button-wrapper {
            mat-icon, .mat-icon {

                &.svg-icon-class_add,
                &.svg-icon-institution_add,
                &.svg-icon-student_add,
                &.svg-icon-trainer_add,
                &.svg-icon-workgroup_add {
                    color: $button-mat-fab-stroke-color;
                    font-size: $button-mat-fab-dimension;
                    width: $button-mat-fab-dimension;
                    height: $button-mat-fab-dimension;
                    min-width: $button-mat-fab-dimension;
                    min-height: $button-mat-fab-dimension;
                    line-height: $button-mat-fab-dimension;
                    transform: scale(1.02);
                }
            }
        }

        &.mat-accent {
            background-color: $button-mat-fab-background-color;
        }

    }

}

.button-tab {
    position: relative;
    height: 20px;

    span {
        background-color: #ede8f0;
        position: absolute;
        top: 0;
        left: 6%;
        color: #743F90;
        padding: 5px 10px;
        border-radius: 7px;
        max-width: 75%;
    }
}

.btn-webapp.mat-raised-button {
    background: linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);
    border-radius: 1.5em;
    margin: 0 40px;
    height: 48px;
    overflow: hidden;
    color: white;
    display: flex;

    .mat-button-wrapper {
        height: 100%;
        padding: 0;
        flex: 1;

        img {
            width: 45px;
            margin-right: 15px;
            align-self: flex-end;
        }

        span {
            font-weight: 800;
            white-space: normal;
            display: block;
            width: 100px;
            line-height: 1.5rem;
            padding-left: 8%;
            padding-right: 0;
        }

        .mat-icon + span {
            padding-left: 0;
            padding-right: 8%;
        }
    }

    &:hover {
        background: rgb(131, 207, 255);
        background: linear-gradient(180deg, rgba(131, 207, 255, 1) 0%, rgba(0, 175, 236, 1) 50%);
    }
}

/*
$grid-breakpoints: (
    xs: 0,
    sm: 600px,
    md: 960px,
    lg: 1280px,
    xl: 1920px
) !default;
*/
%buttonSmallHeight { // = button
    &::before {
        content: '';
        display: block;
        width: 1px;
        padding-top: calc(100% / 6);
        @include media-breakpoint-up('sm') {
            padding-top: calc(100% / 4.5);
        }
    }
}

%buttonSmallHeight { // = button
    &::before {
        content: '';
        display: block;
        width: 1px;
        padding-top: calc(100% / 6);
        @include media-breakpoint-up('sm') {
            padding-top: calc(100% / 4.5);
        }
    }
}

%buttonMediumHeight { // = button
    &::before {
        content: '';
        display: block;
        width: 1px;
        // padding-top: calc(100% / 5);
        padding-top: calc(100% / 4.1); // concepts button
        //@include media-breakpoint-up('sm') {
        //    padding-top: calc(100% / 5);
        //}
    }
}

%buttonBigHeight { // = button
    &::before {
        content: '';
        display: block;
        width: 1px;
        padding-top: calc(100% / 4.5);
        @include media-breakpoint-up('sm') {
            // padding-top: calc(100% / 5);
        }
    }
}
%buttonBigHeightDouble {
    &::before {
        padding-top: calc(100% / 3);
    }
}

%buttonHalfSquareHeight { // = button
    &::before {
        content: '';
        display: block;
        width: 1px;
        padding-top: 100%;
    }
}

%buttonHalfHeight { // = button
    &::before {
        content: '';
        display: block;
        width: 1px;
        // padding-top: calc(100% / 2.5);
        padding-top: calc(100% / 2.5); // text-matching
        @include media-breakpoint-up('sm') {
            padding-top: calc(100% / 2.5);
        }
    }
}

%buttonWideHeight { // = button
    &::before {
        content: '';
        display: block;
        width: 1px;
        padding-top: calc(100% / 9.5);
    }
}

%anim-button {
    &:active {
        transition: transform 1s, opacity 500ms;
        transform: scale(0.90);
        opacity: 0.75;
        box-shadow: none !important;
    }
}

%button-image { // à la base prépare le bouton à recevoir une image
    cursor: pointer;
    width: 100%;
    color: white;
    border-radius: initial;

    -webkit-appearance: none !important;
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    background-size: cover !important;

    box-shadow: none !important;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;

    &:hover .mat-button-focus-overlay, &:hover .mat-button-focus-overlay {
        display: none !important;
    }

    .mat-button-ripple.mat-ripple, .mat-button-focus-overlay, .mat-ripple {
        display: none !important;
    }


    @extend %anim-button;

    .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mat-button-wrapper > span, .mat-content > span, > span {
        order: 1;
        align-items: center;

        > span {

        }

        > .mat-icon {
            order: 2;
            margin-left: auto;
        }
    }

    > .mat-icon {
        order: 2;
        margin-left: auto;
    }

}

%button-wide, BUTTON-WIDE {
    padding: 4% 30px 5% 30px;
    margin: 0 -30px;
    @extend %buttonWideHeight;
}

%button-big, BUTTON-BIG {
    padding: 0 8% 3% 8%;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 1.4em;
    @extend %buttonBigHeight;

    .el2 {
        line-height: 18px;
        font-size: 13px !important;
    }
    &.lineText2, &.lineText3, &.lineText4 {
        @extend %buttonBigHeightDouble;
        line-height: 1.2em;
    }

}

%button-medium, BUTTON-MEDIUM {
    padding: 0 8%;
    font-size: 20px !important;
    @extend %buttonMediumHeight;
}

%button-small, BUTTON-SMALL {
    padding: 0 30px 3.2% 30px;
    @extend %buttonSmallHeight;
}

%button-half, BUTTON-HALF {
    padding: 0 30px 3.2% 30px;
    @extend %buttonHalfHeight;
}

%button-half-square, BUTTON-HALF-SQUARE {
    padding: 0 30px 3.2% 30px;
    @extend %buttonHalfSquareHeight;
}

%button-half-square-blue {
    background-image: imageApp('/buttons/btn-half-square-blue.png') !important;
}

%button-half-square-grey {
    background-image: imageApp('/buttons/btn-half-square-grey.png') !important;
}

%button-half-square-green {
    background-image: imageApp('/buttons/btn-half-square-green.png') !important;
}

%button-half-white {
    background-image: imageApp('/buttons/btn-half-white.png') !important;
}

%button-half-blue {
    background-image: imageApp('/buttons/btn-half-blue.png') !important;
}

%button-half-purple {
    background-image: imageApp('/buttons/btn-half-purple.png') !important;
}

%button-little-purple {
    background-image: imageApp('/buttons/btn-little-purple.png') !important;
}

%button-half-green {
    background-image: imageApp('/buttons/btn-half-green.png') !important;
}

%button-half-green-up {
    background-image: imageApp('/buttons/btn-half-green-up.png') !important;
}

%button-half-grey {
    background-image: imageApp('/buttons/btn-half-grey.png') !important;
}

%button-image-big-purple {
    background-image: imageApp('/buttons/btn-big-purple.png') !important;
}

%button-image-big-white {
    background-image: imageApp('/buttons/btn-big-white.png') !important;
}

%button-image-big-orange {
    background-image: imageApp('/buttons/btn-big-orange.png') !important;
}
%button-image-big-orange-double {
    background-image: imageApp('/buttons/btn-big-orange-double.png') !important;
}

%button-image-big-gold {
    background-image: imageApp('/buttons/btn-big-gold.png') !important;
}

%button-half-grey-light {
    background-image: imageApp('/buttons/btn-half-grey-light.png') !important;
}

%button-image-big-blue {
    background-image: imageApp('/buttons/btn-big-blue.png') !important;
}
%button-image-big-blue-double {
    background-image: imageApp('/buttons/btn-big-blue-double.png') !important;
}

%button-image-wide-blue {
    background-image: imageApp('/buttons/btn-wide-blue.png') !important;
}
%button-image-wide-blue-double {
    background-image: imageApp('/buttons/btn-wide-blue-double.png') !important;
}

%button-image-wide-purple {
    background-image: imageApp('/buttons/btn-wide-purple.png') !important;
}

%button-image-wide-red {
    background-image: imageApp('/buttons/btn-wide-red.png') !important;
}

%button-image-medium-orange {
    background-image: imageApp('/buttons/btn-medium-orange.png') !important;
}

%button-image-medium-blue {
    background-image: imageApp('/buttons/btn-medium-blue.png') !important;
}

%btn-image-medium-purple-reverse {
    background-image: imageApp('/buttons/btn-medium-purple-reverse.png') !important;
}

%btn-image-medium-purple-empty {
    background-image: imageApp('/buttons/btn-medium-purple-empty.png') !important;
}

%button-image-medium-yellow {
    background-image: imageApp('/buttons/btn-medium-orange.png') !important;
    width: 100%;
    height: calc(100vw / 5) !important;
    //min-height: 100px;
    max-height: 109px;
}

%button-image-square-gold {
    width: 52px;
    height: 52px;
    min-width: 52px;
    min-height: 52px;
    border-radius: 0;
    box-shadow: none;
    @extend %button-image;
    justify-content: center;
    padding-bottom: 10px;

    .mat-icon {
        margin: 0;
    }

    background-image: imageApp('/buttons/btn-square-gold.png') !important;
}

%button-image-square-light-blue {
    width: 52px;
    height: 52px;
    min-width: 52px;
    min-height: 52px;
    border-radius: 0;
    box-shadow: none;
    @extend %button-image;
    justify-content: center;
    padding-bottom: 10px;

    .mat-icon {
        margin: 0;
    }

    background-image: imageApp('/buttons/btn-square-light-blue.png') !important;
}

%button-image-square-light-red {
    width: 52px;
    height: 52px;
    min-width: 52px;
    min-height: 52px;
    border-radius: 0;
    box-shadow: none;
    @extend %button-image;
    justify-content: center;
    padding-bottom: 10px;

    .mat-icon {
        margin: 0;
    }

    background-image: imageApp('/buttons/btn-square-light-red.png') !important;
}

%button-image-square-cyan {
    width: 52px;
    height: 52px;
    min-width: 52px;
    min-height: 52px;
    border-radius: 0;
    box-shadow: none;
    @extend %button-image;
    justify-content: center;
    padding-bottom: 10px;

    .mat-icon {
        margin: 0;
    }

    background-image: imageApp('/buttons/btn-square-cyan.png') !important;
}

%button-image-close {
    width: 52px;
    height: 52px;
    min-width: 52px;
    min-height: 52px;
    border-radius: 0;
    box-shadow: none;
    @extend %button-image;
    justify-content: center;
    padding-bottom: 6px;

    .mat-icon {
        margin: 0;
    }

    background-image: imageApp('/buttons/btn-close-purple.png') !important;
}

%button-image-title {
    color: white !important;
    display: flex;
    flex-grow: 1;
    margin-left: 0;
    margin-right: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    // padding-top: 3px; bad vertical text align
    padding-right: 5px;
    font-weight: 600;
}

%button-image-icon {
    min-width: 28px;
    min-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

%svg-icon-rounded {
    border-radius: 1em;
    background-color: white;
}

%button-image-icon-svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    display: flex;
}

//.button-image,
.app-activities-list-educational-level [type="button"] {
    @extend %button-image;
    @extend %button-image-big-purple;
}

%button-medium-yellow {
    @extend %button-image;

}


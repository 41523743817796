 app-followed-list {

    table {

        th, td {
            &.mat-column-type {
                text-align: center;
                .mat-icon {
                    vertical-align: middle;
                }
            }
            &.mat-column-assigned_node_title {
                width: 30%;
            }
            &.mat-column-start_date {
                text-align: center;
            }
            &.mat-column-end_date {
                text-align: center;
            }
            &.mat-column-assigned_user {
                text-align: center;
            }
            &.mat-column-state {
                text-align: center;
            }
            &.mat-column-progress {
                text-align: center;
            }
            &.mat-column-score {
                text-align: center;
            }
            &.mat-column-buttons {
                text-align: right;
            }
        }

    }

}

 .adaptative-popin {
     .mat-dialog-content {
         text-align: center;

         mat-icon {
             height: auto;
             width: 30vh;
         }
     }
 }

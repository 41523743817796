
@font-face {
    font-family: '#{$typography-font-name}';
    font-weight: 400;
    src:
        font("#{$typography-font-name}/#{$typography-font-name}-Regular.otf?");
        //font("#{$typography-font-name}/#{$typography-font-name}-Regular.eot?"),
        //font("#{$typography-font-name}/#{$typography-font-name}-Regular.woff2") format('woff2'),
        //font("#{$typography-font-name}/#{$typography-font-name}-Regular.woff") format('woff'),
        //font("#{$typography-font-name}/#{$typography-font-name}-Regular.ttf") format('truetype');
}
@font-face {
    font-family: '#{$typography-font-name}';
    font-weight: 700; // ? 700
    src:
        font("#{$typography-font-name}/#{$typography-font-name}-Bold.otf?");
        //font("#{$typography-font-name}/#{$typography-font-name}-Bold.eot?") format('eot'),
        //font("#{$typography-font-name}/#{$typography-font-name}-Bold.woff2") format('woff2'),
        //font("#{$typography-font-name}/#{$typography-font-name}-Bold.woff") format('woff'),
        //font("#{$typography-font-name}/#{$typography-font-name}-Bold.ttf") format('truetype');
}

html {
    font-size: 62.5%;
    line-height: 1.4 !important;
    letter-spacing: -0.1px !important;
    font-family: #{$font-family};
    color: map_get($mat-fusedark, 500);
}

body {
    font-size: $base-font-size;
}

//@font-face {
//    font-family: '#{$typography-font-name2}';
//    font-weight: 400; // ? 700
//    src:
//        //font("#{$typography-font-name2}/#{$typography-font-name2}-Regular.eot?") format('eot'),
//        font("#{$typography-font-name2}/#{$typography-font-name2}-Regular.woff2") format('woff2'),
//        //font("#{$typography-font-name2}/#{$typography-font-name2}-Regular.woff") format('woff'),
//        //font("#{$typography-font-name2}/#{$typography-font-name2}-Regular.ttf") format('truetype');
//}


app-image-zoning-play-sound {
    audio {
        visibility: hidden;
    }

    .selected-true {
        border: 2px solid rgba(255, 255, 255, 0.33);
        border-radius: 2px !important;
        background-color: transparent !important;
    }

    .selected-false {
        cursor: default;
    }

    .answer-was-selected-almost-once {
        border: 2px solid #00C29F;
        border-radius: 2px !important;
    }

    // mean playing sound in progress in this activity
    .currently-correct {
        border: 2px solid #1E35FC;
        border-radius: 2px !important;
    }

    app-zone-generator .generated-column .generated-row-element .colored-zone.colored-zone:active {
        background-color: transparent !important;
        opacity: 1 !important;
    }

    .available-answers {
        .container {
            border: 2px solid #0010A7 !important;
            border-radius: 2px !important;

            img {
                border-radius: 0 !important;
            }

            &.false-answer {
                border: 2px solid #0010A7 !important;
                border-radius: 2px !important;
            }

            &.true-answer {
                border: 2px solid #0010A7 !important;
                border-radius: 2px !important;
            }

        }
    }

    .generated-row-element {
        .selected-false {
            &:hover {
                cursor: default !important;
            }
        }
    }

    mat-card-footer {
        button {
            &:disabled {
                cursor: not-allowed;
                background: #707ded;

                border: none;

                span {
                    opacity: 0.8;
                }
            }

            &:enabled {
                cursor: pointer;
                opacity: 1;
                background: #0b1bab;

                span {
                    opacity: 1;
                }
            }
        }

    }

}
/*
outlet-player-navigation
    .page-layout
        .center
            .header
            content
                app-progress-multi-zone
                .outlet-player-navigation
                    app-multi


 */
@use '@angular/material' as mat;

#lessons app-stepper-wrapper > .page-layout > .center {
    > content {
        flex: 1;
        display: flex;
    }
}

.outlet-player-navigation {
    height: 100%;
    //flex: 1;
}


fuse-app-lessons {
    #lessons {
        height: 100%;
    }

    #lessons app-stepper-wrapper {
        //background-color: rgba(map_get($md-bluedark, 500), 0.6);

        button {
            &.mat-fab.mat-accent,
            &.mat-mini-fab {
                background-color: inherit;
                box-shadow: none;

                svg {
                    color: $primary-c;
                }
            }
        }

        > .page-layout {
            //background-color: map_get($md-bluedark, 500);
            //background-color: rgba(0, 65, 87, 0.5);

            > .center {
                height: 100%;
                //margin-top: 1%;
                //margin-bottom: 1%;
                width: 100%;
                //background-color: #004157;
            }

            .fuse-app-lessons-header {
                background-color: inherit;
                height: 55px;
                padding: 5px;
                flex: none;

                .header-top {
                    padding-top: 30px;

                    .help, .close_header {
                        @extend %button-image-close;

                        mat-icon {
                            padding: 2px;
                            transform: translateY(-3px);


                            path {
                                fill: white !important;
                            }
                        }
                    }

                    .help {
                        margin-left: -5px;
                        padding-top: 5px;
                    }

                    .close_header {
                        margin-right: -5px;
                        padding-top: 5px;
                    }

                    .title {
                        margin-top: -5px;
                    }


                    mat-menu {
                        display: none;
                    }

                    img {
                        height: 5rem;
                    }

                    .title {
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 24px;
                        text-align: center;
                        color: #743F90;
                    }

                    button {
                        &:active {
                            transition: transform 1s, opacity 500ms;
                            transform: scale(0.90);
                            opacity: 0.75;
                        }
                    }
                }
            }

            .outlet-player-navigation {
                padding-bottom: 10px;

                > .previous-wrapper, > .next-wrapper {
                    display: none;
                }

                app-multi {
                    width: 100%;
                    flex: 1 1 100%;
                }
            }

            app-external {
                flex: 1;

                mat-card.activities-card {
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    background-color: transparent;


                    .mat-card-header {
                        display: none;
                        flex: none;
                    }

                    .mat-card-content {
                        $main-top-toolbar-height: 64px;
                        $fuse-app-lessons-header-height: 34px;
                        overflow: hidden;
                    }
                }
            }


            .multi-side-nav-previous, .multi-side-nav-next {
                display: none !important;
            }

            .multi-content {
                width: 100%;
                padding: 0 !important;
                padding-top: 0;
                padding-bottom: 0;
                margin-top: 0;
                margin-bottom: 0;

                mat-card.activities-card {
                    background-color: inherit;
                    color: white;
                    padding-top: 0;
                    padding-bottom: 0;

                    .mat-card-title {
                        margin: 0;
                    }

                    .medias {
                        padding: 0;

                        > div {
                            width: 100%;

                            .media-wrapper {
                                height: 50vh;
                                width: 100%;
                                text-align: center;

                                video, img {
                                    height: 100%;
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }

            app-multimedia {
                padding-top: 0;

                .mat-card {
                    background-color: transparent;

                    mat-card-content.mat-card-content {
                        justify-content: flex-start !important; // overide html flex
                        .media-wrapper {
                            justify-content: flex-start !important; // overide html flex
                            text-align: left;
                        }
                    }

                }
            }

        }
    }
}

.summary {
    // when summary is open whe need it to have scroll bar at he more outside right
    // but not at other time to not break design of exo
    fuse-app-lessons {
        display: block;
    }
}

fuse-app-lessons-tab {
    padding: 16px 0 0 16px;

    .mat-toolbar-row, .mat-toolbar-single-row {
        flex: none;
        height: auto;
    }

}

app-lesson-editor {
    mat-spinner {
        margin: 25% auto;
    }
}

app-lesson-editor {

    div.page-layout.simple.fullwidth > .header {
        &.lesson-title {
            background-color: $secondary-c;
            font-weight: bold;
            font-size: 30px;
            line-height: 44px;
            background-image: none;
            justify-content: flex-start;
            height: 70px;

            h2 {
                font-size: 30px;
                color: #FFFFFF;
            }
        }
    }

    mat-spinner {
        margin: 25% auto;
    }

    .content {
        padding-top: 35px;
        padding-right: 50px;
        margin-left: 25px;

        mat-spinner {
            margin: auto;
        }

        .lesson-editor-helper {
            margin-bottom: 50px;
            color: map_get($mat-black, 500);
            font-weight: bold;
            font-size: 20px;
            line-height: 129%;
        }

        app-generic-activity {

            button.mat-fab:not([class*=mat-elevation-z]) {
                box-shadow: none;
            }

            button.add-page.mat-fab.mat-accent {
                background: #FFB08F !important;

                .mat-icon {
                    transform: scale(2.5);
                }

                path {
                    fill: mat.get-color-from-palette($accent);
                }
            }

            .add-page-label {
                margin-bottom: 20px !important;
            }

            .activity-bar {
                background: #FFFFFF;
                box-shadow: 0px 1px 10px rgba(0, 175, 236, 0.25);
                border-radius: 5px;
                height: 70px;
                display: flex;
                place-content: center;
                align-items: center;
                flex-direction: row;
                padding: 0 15px 0;
                margin-bottom: 15px;

                .left {

                    svg path {
                        fill: map-get($md-blue, 500);
                        stroke: map-get($md-blue, 500);
                        color: map-get($md-blue, 500);
                    }

                    button.mat-fab:not([class*=mat-elevation-z]) {
                        background-color: transparent;
                    }

                }

                .middle {
                    flex-grow: 1;
                    margin: 0 20px;

                    .inject-middle {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        font-weight: bold;
                        font-size: 20px;

                        span {
                            margin-right: 10px;
                            color: map_get($mat-black, 500);
                            font-weight: normal;
                            font-size: 20px;
                            line-height: 29px;
                        }

                        .mat-icon {
                            margin-right: 17px;
                            background-color: map-get($md-blue, 500);
                            border-radius: 2em;
                            color: mat.get-color-from-palette($accent);
                            padding: 9px;
                            display: none; // to remove if this icon will be used
                        }
                    }
                }

                .inject-left {
                    .mat-button-wrapper {
                        height: 50px;
                    }

                    .mat-icon {
                        width: 35px;
                        height: 35px;
                    }
                }

                .right {
                    button { // It's a mat-fab button and so already rounded
                        background-color: map-get($md-blue, 500);
                        width: 32px;
                        height: 32px;

                        .mat-icon {
                            svg path {
                                color: white;
                                fill: none !important;
                                stroke: white !important;
                            }
                        }
                    }


                }

                .left, .middle {
                    mat-icon {
                        width: 30px;
                        height: 30px;
                    }
                }

            }
        }
    }

    .add-step-button {
        flex: 1;
        margin: 10px 30px;
        padding: 10px 35px;
        background: map-get($md-blue, 500) !important;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.4px;

        .mat-button-wrapper {
            justify-content: left;
        }
    }

    .exit-button, .save-button:not(.add-step-button) {
        background-color: mat.get-color-from-palette($accent);
        color: #ffffff;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.75px;
        border-radius: 200px;

        mat-spinner {
            margin: 0;
        }
    }

    button.mat-fab.mat-accent.add-file-button {
        background-color: #1a1a4e !important;
    }

    /**counter inside row in editor**/
    counter-reset: section; // init counter
    app-one-line-activity {
        .inject-middle::before {
            counter-increment: section; /* increment counter */
            content: counter(section); /* text of counter to show*/
            color: #ffffff;
            background-color: #00afec;
            border-radius: 28px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            margin-right: 17px;
        }
    }
}
app-multimedia {
    mat-card {
        max-height: 72vh;
        .medias {
            &.listen {
                justify-content: center !important;
            }
            flex: 1;
            margin-bottom: 20px;
        }
        .instruction {
            color: #005D92;
        }
    }
    .text {
        &.read {
            margin-top: 10px;
        }
        @extend %textCartouche !optional;
        &.media-wrapper {
            align-items: flex-start !important;
            > div p {
                font-style: normal !important;
                font-weight: 700 !important;
                font-size: 14px !important;
                line-height: 20px !important;
            }
        }
    }

    app-button-element{
        .btn-option {
            @extend %button-image;
            @extend %button-half;
            @extend %button-half-blue;
            border: none;
            margin: 0 auto 0 auto !important;
            justify-content: center;
            color: white;
            width: 50%;
            height: auto;
            padding: 0 20px 3.2% 20px;
            max-width: 150px !important;

            position: relative;
            > span {
                position: absolute;
                top: 41%;
                transform: translateY(-50%);
            }
        }
    }

    .activities-card app-consignes .is-instruction {
        background: transparent !important;
    }

    .btn-tts-wrapper {
        flex-direction: column;
        box-sizing: border-box;
        display: flex;
        place-content: stretch flex-start;
        align-items: center;
        align-content: center;
        .btn-tts{
            width: 150px;
            height: 150px;
            border-radius: 25px;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            align-items: center;
            background: white;
            border: 1px solid #00000017;
            box-shadow: 0px 15px 20px #0000001c;
            cursor: pointer;
            transform : scale(1);
            transition: 0.2s;
            transition-timing-function: ease-in;
            &.tts-reading {
                transform : scale(0.95);
                box-shadow: 0px 10px 15px #0fa9ff36;
                transition: 0.2s;
                transition-timing-function: ease-in;
            }
            mat-icon {
                width: 75px;
                height: 75px;
                color: #005D92;
                transition: 0.2s;
                transition-timing-function: ease-in;
                &.read {
                    transition: 0.2s;
                    transition-timing-function: ease-in;
                    color: #0FA9FF;
                }
            }
        }
    }

}

app-notepad-list {

    mat-spinner {
        margin: 25% auto; // Why 25% ? I don't know but it's precisely in the middle of the page
    }

    .notepad-card-list {
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        max-height: 100%;
        place-content: flex-start;
        align-items: flex-start;

        .mat-card .mat-card-content, .mat-card .mat-card-actions  {
            margin-left: 244px;
        }
        .mat-card .mat-card-content  {
            flex: 1;
            .dl-listitem-inline dt, .dl-listitem-inline dd {
                display: inline;
                max-width: 70%;
            }
            img.mat-card-image {
                max-height: 250px;
            }
        }
    }

    mat-paginator {
        padding-right: 70px;
    }

    button.mat-fab-bottom-right {
        z-index: 1;
    }
}

vertical-layout-1 #main > .container > .container > .container content > app-notepad-content-editor > .page-layout > .content {
    padding: 0 !important;
}

app-notepad-content-editor {

    mat-spinner {
        margin: 25% auto; // Why 25% ? I don't know but it's precisely in the middle of the page
    }

    .content-editor {
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        place-content: stretch flex-start;
        align-items: stretch;

        .notepad-header {
            font-style: normal;
            font-size: 20px;
            line-height: 24px;
            padding-left: 21px;
            padding-top: 14px;

            button.mat-mini-fab.mat-accent {
                float: right;
            }
        }

        .notepad-content {
            height: 100%;
            max-height: 50vh;
            flex: 1 1 auto;

            ckeditor,
            .ck-editor__main,
            .ck-reset,
            .ck-content {
                height: 100%;
            }
        }

        .notepad-action {
            display: flex;
            flex-direction: row;
            flex: 1 0 auto;
            place-content: center;
            align-items: center;

            button {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }

                mat-spinner {
                    margin: 0;
                }
            }
        }
    }
}


mat-dialog-container.mat-dialog-container app-notepad-data-editor-modal {
    .mat-dialog-content {
        padding: 0;

        form {
            padding: 0 10px 10px 10px;

            mat-list-item {
                mat-spinner {
                    margin: 0;
                }
            }
        }

        mat-spinner {
            margin: 25% auto; // Why 25% ? I don't know but it's precisely in the middle of the modal
        }
    }
}


mat-dialog-container.mat-dialog-container.mat-dialog-container { // for a heavier selector
    width: auto;
}


app-notepad-card.card-split-media-info {
    mat-card-content {
        height: 100%;

    }
    mat-card-actions.mat-card-actions  {
        place-content: flex-end;
    }

}
.mat-card {
    .mat-card-content {
        .latex-wrapper {
            &.longAnswer {
                app-keyboard-latex-field {
                    .latex {
                        .katex {
                            .katex-html {
                                .base {
                                    display: flex;
                                    flex-wrap: wrap;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
app-fill-in-blanks {
    display: flex;
    @extend %activities-card;
    flex: 1;

    // todo change color of text for ubolinoo because it is white on white
    .mat-card {
        padding: 0;
    }

    .mat-card-content {
        color: #000;
    }

    .rb-question-text .renderedInputContent,
    .base {
        color: #005D92;
    }

    .rb-question-text {
        background-color: rgba(15, 169, 255, 0.1);
        padding: 20px;
        text-align: center;
        border-radius: 10px;
        styleName: H3 Bold 18px;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color: #005D92;

        .latex-wrapper {
            font-family: #{$font-family};
            .katex {
                font-family: inherit;
                font-family: "drBuch", "Helvetica Neue", Arial, sans-serif;
                font-style: normal;
                font-size: 18px;
                letter-spacing: -0.06em;
            }
            .renderedInputContent {
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 2px solid #005D92;
                border-radius: 0;

                padding-left: 14px !important;
                padding-right: 14px !important;

            }
            &.correction .renderedInputContent {
                //padding-left: 14px;
                //padding-right: 14px;
            }

            &.correction .base, .incorrect .base, .incorrect.base {
                color: #3A3A3A;
            }
            &.missingResponse .base {
                color: #FFA615;
            }
            &.right .base, .currently-correct .base, .currently-correct.base  {
                color: #89E30C;
            }
            &.wrong .base, .wrong .base, .wrong.base {
                color: #FF6862;
            }
            &.validated .base {
                color: #17D0AD;
            }
        }
    }



    #container-for-answers-available button.btn-option {
        @extend %button-image;
        @extend %button-half;
        @extend %button-half-blue;
        border: none;
        margin: auto auto 0 auto !important;
        justify-content: center;
        color: white;
        width: 50%;
        height: auto;
        padding: 0 30px 3.2% 30px;

        &[disabled="true"], &.mat-raised-button[disabled][disabled] {
            // color: rgba(255, 255, 255, 1);
            color: white;
        }

        &.pristine { // perfect
            //@extend %button-half-green;
        }
        &.was-correct {
            opacity: 0.3;
            cursor: not-allowed;
        }
        &.incorrect {
            @extend %button-half-grey;
            color: white;
        }
        &.currently-correct {
            @extend %button-half-green;
            color: white;
        }
    }

}
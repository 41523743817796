/* ***************************************************************************** */
@use '@angular/material' as mat;
@use 'sass:math' as math;
// Appairement
/* ***************************************************************************** */

$badge-size: 40px;
$badge-center: math.div($badge-size, 2);
$block-min-height: 90px;
$block-min-height-vertical: 90px;
$block-margin: 10px;
$block-answers-spacer: 10px;
$bag-answers-spacer: 10px;

// ----------- DRAGULA
// ng2-dragula ----------
// https://github.com/valor-software/ng2-dragula#3-add-the-css-to-your-project
// .gu-mirror, .gu-hide, .gu-unselectable, .gu-transit are in default _activities-modules.scss

%block-style {
    border: 3px solid #008a9c;
    min-height: $block-min-height;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 5px 15px;
}

.gu-mirror {
    @extend %btn-container;

    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;

    @extend %block-style;

}

.gu-transit {
    .counting-circle {
        display: none;
    }
}

.gu-hide {
    display: none !important;
    left: -9999px !important;
}

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);

    .counting-circle {
        display: none;
    }
}


app-appaire, app-text-matching { //TODO a Fusionner

    mat-card-content.mat-card-content {
        padding: 25px 25px 0 0;
        padding-top: calc((#{$badge-size} / 2) + (#{$block-margin} / 2));

        img.formula {
            width: auto;
            max-width: 100%;
        }

        .bag {
            display: flex;
            justify-content: space-around;
            flex: 1 1 auto;
            min-height: $block-min-height;

            .mat-mini-fab {
                position: absolute;
                z-index: 10;
                width: $badge-size;
                height: $badge-size;

                .mat-icon {
                    width: 80%;
                    height: 80%;
                    min-width: 80%;
                    min-height: 80%;
                    display: flex;
                    align-items: center;
                    place-content: center;
                }
            }

            .static-block,
            .block-left {
                @extend %block-style;
                flex: 1 1 0%;
                margin: 0 $block-margin 0 0;
                position: relative;

                .mat-mini-fab {
                    bottom: calc(100% - (#{$badge-size} / 2));
                    left: 100%;
                    transform: translateX(- #{$badge-center});
                    z-index: 10;
                }
            }

            &.left {
                margin-bottom: $bag-answers-spacer;
            }

            .answers-bag {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                flex: 1 1 0%;
                margin: 0 $block-margin 0 0;
                min-height: ($block-min-height * 2) + $block-answers-spacer;

                .static-block,
                .block-left {
                    width: 100%;
                    flex: 0 1 0%;
                    margin: 0;
                }

                .static-block {
                    order: 1;
                    margin-top: $block-answers-spacer;

                    .mat-mini-fab {
                        bottom: calc(100% - ((#{$badge-size} / 2) - #{$block-answers-spacer} / 2));
                        left: 50%;
                    }
                }
            }

            .cloned {
                opacity: 0.4;
            }
        }

        &.vertical {
            flex-direction: row;

            .ps__rail-y {
                display: none;
            }

            .bag {
                height: 100%;
                flex-direction: column;
                box-sizing: border-box;
                display: flex;
                place-content: stretch flex-start;
                align-items: stretch;

                &.left {
                    margin-right: calc(#{$badge-size} / 2 + (#{$block-answers-spacer} / 2));
                    flex: 0 0 33%;
                }

                &:not(.left) {
                    margin-left: calc(#{$badge-size} / 2 + (#{$block-answers-spacer} / 2));
                }

                &.left .block-left, .answers-bag {
                    margin-right: 0;
                    margin-bottom: calc(#{$badge-size} / 2 + (#{$block-answers-spacer}));
                    min-height: $block-min-height-vertical;
                }

                .answers-bag {
                    width: unset;
                    flex-direction: row;
                    justify-content: flex-end;

                    .static-block {
                        min-height: $block-min-height-vertical;
                        margin-top: 0;
                        //flex: 1 1;
                        flex: 0 1 calc(50% - #{$block-answers-spacer} / 4);
                        margin-left: calc(#{$block-answers-spacer} / 2);

                        .mat-mini-fab {
                            left: inherit;
                            right: calc(100% - 35px);
                        }
                    }

                    .block-left {
                        width: calc(50% - #{$block-answers-spacer} / 4);
                        height: 100%;
                        margin-top: 0;
                        flex: 1 1;
                        min-height: $block-min-height-vertical;

                        & + .static-block {
                            width: calc(50% - #{$block-answers-spacer} / 4);
                            height: 100%;
                            flex: 1 1;
                        }
                    }
                }
            }
        }

        &.ps {
            justify-content: space-evenly;
        }
    }

    .activities-card {

        li {
            border-color: mat.get-color-from-palette($primary);
        }

        ul.ques-part > li {
            &.draggable[draggable="false"] {
                border-color: $warning-color;

                &.appaire-rezet {
                    border-color: mat.get-color-from-palette($primary);
                }
            }

            &.dropped {
                &.success li {
                    border-color: $success-color;
                }

                &.error li {
                    border-color: $error-color;
                }
            }
        }
    }
}


.row-btn-container {
    @extend %row-btn-container;

    &.gu-transit {
        padding-bottom: 40px;
    }
}


// app-option is the mat-card-content of qcm, qcu, ordon, appaire, true-false
app-true-false,
app-qcm,
app-qcu,
app-what-is-missing,
app-what-is-missing-audio,
app-options,
app-image-zoning, app-image-zoning-play-sound { //= mat-card-content
    display: contents;
    margin: 0;
    font-family: 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
    font-size: 20px;

    mat-radio-group.mat-radio-group,
    div.mat-checkbox-group {
        margin: 0;
    }

    .mat-radio-group, .mat-checkbox-group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* autoprefixer: off */
        grid-gap: 35px;
        grid-auto-rows: 100px;
        margin: 0 15%;
    }

    .mat-radio-button, .mat-checkbox {
        border: 3px solid #979797;
        display: flex;
        // app-options .mat-radio-button, .mat-checkbox ...
        &.validated {
            background: #008A9C;
            border: 3px solid #008A9C !important;
        }

        &.correctAnswer, &.success {
            background: #679A00;
            border: 3px solid #679A00 !important;
        }

        &.answerMissed, &.warning {
            background: #ED6723;
            border: 3px solid #ED6723 !important;
        }

        &.wrongAnswer, &.error {
            background: darkred;
            border: 3px solid darkred !important;
        }

        &.selected:not(.mat-radio-disabled, .mat-checkbox-disabled) {
            border: 3px solid #008A9C;
        }

        &.mat-checkbox-disabled, &.mat-radio-disabled {
            &.mat-accent.mat-radio-checked {
                .mat-radio-checked {
                    border-color: rgba(0, 0, 0, 0.38);
                }
            }

            .formula {
                opacity: 0.5;
            }
        }

        &.validated, &.correctAnswer, &.wrongAnswer, &.answerMissed {
            .mat-radio-outer-circle, .mat-radio-inner-circle,
            .mat-checkbox-frame, .mat-checkbox-background {
                display: none;
            }

            .mat-radio-container, .mat-checkbox-inner-container {
                background-size: 20px 20px;
                background-position: center;
                height: 100%;
            }

            &.mat-checkbox-disabled, &.mat-radio-disabled {
                .mat-radio-label-content, .mat-checkbox-label {
                    color: rgba(0, 0, 0, 0.85);

                    .formula {
                        opacity: 1;
                    }
                }
            }
        }

        // common radio && checkbox
        .mat-radio-label,
        .mat-checkbox-layout {
            display: flex;
            justify-content: space-between;
            width: 100%;
            white-space: normal;
        }

        .mat-radio-label .mat-radio-container,
        .mat-checkbox-layout .mat-checkbox-inner-container {
            display: flex;
            flex: 0 0 60px;
            position: relative; // for checkbox exist on radio-container
        }

        //radio
        .mat-radio-label {
            .mat-radio-container {
                .mat-radio-outer-circle, .mat-radio-inner-circle {
                    left: calc(50% - 10px);
                    top: calc(50% - 10px);
                }
            }

            .mat-radio-label-content {
                display: flex;
                flex: 1 1 auto;
                background-color: transparent;
                align-self: stretch;
                align-items: center;
                padding: 8px;
                word-break: break-word;
                color: black;
            }
        }

        //checkbox
        .mat-checkbox-layout {
            .mat-checkbox-label {
                display: flex;
                flex: 1 1 auto;
                background-color: transparent;
                align-self: stretch;
                align-items: center;
                padding: 8px;
                justify-content: left;
                word-break: break-word;
            }

            .mat-checkbox-inner-container {
                .mat-checkbox-frame, .mat-checkbox-background {
                    width: 20px;
                    height: 20px;
                    top: calc(50% - 10px);
                    left: calc(50% - 10px);
                }
            }
        }
    }

    .showFinalAnswers {
        .mat-radio-inner-circle, .mat-checkbox-background {
            display: none;
        }
    }
}


/* ***************************************************************************** */
// Audio
/* ***************************************************************************** */
app-audio, {
    flex: 1;
    background-color: aquamarine;
    display: flex;
    flex-direction: column;

}

/* ***************************************************************************** */
// Divider
/* ***************************************************************************** */
app-divider {
    display: flex;
    flex: 1;
}

/* ***************************************************************************** */
// External
/* ***************************************************************************** */

/* ***************************************************************************** */
// fill in blanks
/* ***************************************************************************** */
app-fill-in-blanks {

    .renderedContent {
        .msg-icon {
            position: relative;
            border-radius: 4px;
            line-height: normal;

            display: inline-block;
            margin: auto 5px;
            padding: 0 15px 0 28px;
            background-image: none;
            background-color: white;
            line-height: normal;
            min-height: 30px;

            &.validated {
                display: inline-block;
                border: 2px solid mat.get-color-from-palette($primary);
                margin: auto 5px;
                //padding: 0px 20px;
                border-radius: 4px;
            }

            &::before {
                position: absolute;
                top: 0px;
                left: -1px;
                content: '';
                width: 21px;
                height: 105%;
                display: inline-block;
                background-size: 14px 14px;
                background-position: 5px center;
                background-repeat: no-repeat;
                border-radius: 1px 0 0 1px;
                padding: 0px 1px 1px 1px;
                box-sizing: content-box;
            }

            &.right::before {
                background-position: 2px center;
            }

            &.wrong::before {
                background-position: 5px center;
            }

            .ans-txt {
                line-height: inherit;
                display: inline-block;
                float: none;
                min-width: inherit;
                height: auto;
                padding: 2px 0 0 0;
            }

            &.wrong, &.missingResponse, &.right { // ! should be use same flag inner all activities
                .mat-icon {
                    display: none; // use background image
                }
            }
        ;

            &.missingResponse::after { // set height in empty field
                content: '';
                display: inline-block;
                padding-top: 2px;
                min-height: 17px;
            }

        }
    }

    table .msg-icon {
        min-width: 90%;
    }
}

app-fill-in-blanks-child {

    .renderedContent {
        display: block;
        height: 5%;
        width: 100%;
        margin: 0;
        padding: 0;
        line-height: 2;
    }

    .ng-star-inserted {
        display: inline-block;
        max-height: 5%;
    }

    p {
        margin: 0 !important;
        display: block;
        padding: 0;

        span {
            margin: 0;
            padding: 0;
        }
    }

    .rightSide {
        .container {
            margin: 0;
        }
    }

    .renderedInputContent {
        display: inline-block;
        border: 2px solid mat.get-color-from-palette($primary);
        margin: auto 5px;
        padding: 0 20px;
        border-radius: 4px;
    }

    .latex-wrapper {
        display: inline-flex;
        position: relative;
        vertical-align: middle;

        .renderedInputContent {
            padding-right: 28px;
        }

        .msg-icon {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
        }

        &.correction {
            .renderedInputContent {
                padding-left: 34px;
                padding-right: 14px;
            }
        }
    }

    #latex-keyboard > div {
        z-index: 10000;
    }

    .right {
        display: inline-block !important;
        border: 3px solid #679A00;
    }

    .wrong {
        display: inline-block !important;
        border: 3px solid darkred;
    }

    .missingResponse {
        display: inline-block !important;
        border: 3px solid #ED6723;
    }

    .msg-icon {
        .mat-icon {
            color: #ffffff;
            display: none;
            float: left;
            min-height: 20px !important;
        }

        .ans-txt {
            padding: 0 5px;
            float: left;
            min-width: 40px;
        }
    }
}

/* ***************************************************************************** */
// image
/* ***************************************************************************** */


/* ***************************************************************************** */
// interactive-image
/* ***************************************************************************** */


/* ***************************************************************************** */
// media
/* ***************************************************************************** */

app-media {
    display: flex;
    flex-direction: column;
    text-align: center;

    mat-card {
        .videoUrl {
            flex: 1;
            justify-content: center;
        }
    }
}


/* ***************************************************************************** */
// multi
/* ***************************************************************************** */


/* ***************************************************************************** */
// multimedia
/* ***************************************************************************** */
app-multimedia, app-discover-audio, app-discover-video {
    padding-top: 30px;

    .mat-card {
        mat-card-content.mat-card-content {
            .media-wrapper {
                width: 100%;
                height: 100%;
                padding-bottom: 20px;

                justify-content: center;
                align-items: center;
                display: flex;

                text-align: center;

            }
        }
    }
}

/* ***************************************************************************** */
// multiple choice grid
/* ***************************************************************************** */


/* ***************************************************************************** */
// ordon
/* ***************************************************************************** */
app-ordon {
    //background-image: icon('compass.svg');

    .ans-part {
        padding-bottom: 16px;

        .button-row {
            display: flex;
            flex-direction: column;

            &.button-row-flex {
                flex-direction: row;
            }

            .btn-container {
                @extend %btn-container;

                //&.row-btn-container {
                //    @extend %row-btn-container;
                //}
            }
        }
    }
}

app-ordon { //TODO a Fusionner
    button.answerMissed {
        border: 3px solid $warning-color;
    }

    button.correctAnswer {
        border: 3px solid $success-color;
    }

    button.wrongAnswer {
        border: 3px solid $error-color;
    }
}

/* ***************************************************************************** */
// poll
/* ***************************************************************************** */


/* ***************************************************************************** */
// poll-review
/* ***************************************************************************** */


/* ***************************************************************************** */
// qcm
/* ***************************************************************************** */
.qcmContainer {
    // width: 100%;
    .multipleOptions {
        .optionsToBeSelectecd {
            figure {
                margin: 0 3%;
            }

            .activityOptions {
                flex: 1;
                height: 100%;
            }
        }
    }

    .Div-multipleOptions {
        /*display: grid;instruction
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 35px;
        grid-auto-rows: 100px;
        margin: 0 15%;*/
        .Div-optionsToBeSelectecd {
            .Div-activityOptions {
                /* flex:1;
                 height: 100%;*/
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                /* autoprefixer: off */
                grid-gap: 35px;
                grid-auto-rows: 100px;
                margin: 0 15%;
            }
        }
    }
}

/* ***************************************************************************** */
// qcu
/* ***************************************************************************** */
.activityOptions {
    /* flex:1;
     height: 100%;*/
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* autoprefixer: off */
    grid-gap: 35px;
    grid-auto-rows: 100px;
    margin: 0 15%;
}


/* ***************************************************************************** */
// short-answer
/* ***************************************************************************** */
app-short-answer, app-short-answer-open-answer {
    // DRAFT TEMP
    .divResponseTextarea { // bad target
        border: 3px solid #979797;
        padding: 10px;
        width: calc(60% + 60px);
        margin: 0 20% 0 calc(20% - 60px);
        min-height: 150px;
        display: flex;
        justify-content: flex-end;
        position: relative;
        flex-direction: row;

        span { // :'(
            background-color: white;
            flex: 0 0 100%;
            padding: 8px 10px 10px 9px;
        }

        &.validated {
            border-color: #008A9C;

            span:first-child { // :'(
                display: flex;
            }
        }

        &.missingReply { // &.answerMissed
            border-color: #ED6723;

            span:first-child { // :'(
                display: flex;
            }
        }

        &.correctReply { // &.answerMissed
            border-color: #679A00;

            span:first-child { // :'(
                display: flex;
            }
        }

        &.wrongReply { // &.answerMissed
            border-color: darkred;

            span:nth-child(2) { // :'(
                display: flex;
            }
        }

        &.correctReply { // &.answerMissed
            span { // :'(
                &:first-child {
                }

                &:nth-child(2) { // :'(
                    display: flex;
                }
            }
        }

        &.validated, &.missingReply, &.wrongReply, &.correctReply {
            padding: 0 0 0 60px;
        }


        .mat-icon { // unnecessary temp drop dom
            display: none;
        }
    }

    .latex-wrapper {
        display: inline-block;
        position: relative;

        border: 3px solid #979797;
        padding: 10px;
        min-height: 15px;
        margin: 0% 10% 0% 10%;
        width: 80%;

        &.correctReply {
            background: initial;
            border-color: #679A00;
        }

        &.wrongReply {
            background: initial;
            border-color: darkred;
        }

        &.missingReply {
            background: initial;
            border-color: #ED6723;
        }

        &.validated {
            background: initial;
            border-color: #008A9C;
        }

        .correction {
            position: absolute;
            top: -3px;
            left: -60px;
            bottom: -3px;
            width: 60px;
        }

        #latex-rendering {
            display: inline-block;
            height: auto;
            min-height: 15px;
            width: calc(100% - 20px);
            cursor: text;
        }

        .divResponseTextarea {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
        }
    }

    #latex-keyboard > div {
        z-index: 10000;
    }
}

/* ***************************************************************************** */
// summary
/* ***************************************************************************** */


/* ***************************************************************************** */
// tool
/* ***************************************************************************** */


/* ***************************************************************************** */
// true-false
/* ***************************************************************************** */
app-true-false {
    .trueFalseContainer {
        // width: 100%;
        .trueFalseOption {
            .mat-card-content {
                .wording {
                    color: #008A9C;
                }
            }

            .optionsToBeSelectecd {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                /* autoprefixer: off */
                grid-gap: 35px;
                grid-auto-rows: 100px;
                margin: 0 15%;

                figure {
                    margin: 0 3%;
                }

                .activityOptions {
                    flex: 1;
                    height: 100%;

                }
            }
        }

        app-options .mat-radio-label-content {
            text-transform: capitalize;
        }
    }
}

app-draw-line {
    .mat-card.mat-card {
        align-items: center;
    }

    app-consignes {
        .is-instruction, .wording {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: transparent !important;

            .instruction {
                margin: 0 0 10px;
                text-align: center;
                color: #005D92;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 19px;
                letter-spacing: 0;

            }

            .mat-icon {
                color: #005D92;
            }
        }
    }

    .canvas-wrapper {
        margin: 0 auto;
    }
}

/* ***************************************************************************** */
// video
/* ***************************************************************************** */
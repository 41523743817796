.menu-link {
    margin-bottom: 40px;
    justify-content: center;
}

mat-sidenav-container {
    mat-sidenav{
        background-color: $default-background-color !important;
    }
    &.footerVisible {
        background-color: $default-background-color !important;
    }
}

.wrapper-menu-item-external-target-link-menu {
    // custom css for external button
}

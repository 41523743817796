
fuse-app-lessons-tab {
    height: 100%;
    .content {
        height: 100%;
    }

    mat-spinner {
        margin-top: 15%;
    }
}


.mat-tab-group, .mat-tab-body-wrapper {
    height: 100%;
}

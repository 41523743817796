/* ******************************* CUSTOM VALUES ****************************************/
@use '@angular/material' as mat;
// Typography
$base-font-size: 1.4rem;
$font-family: '"drBuch", "Helvetica Neue", Arial, sans-serif';
$typography-font-name: 'drBuch';
$typography-font-name2: 'drBuch';

// Typographie
$default-font-color: map_get($md-bluedark, 900);
$default-font-contrast-color: map_get($mat-white, 500);
$default-font: '500 16px/24px #{$font-family};';

// background color off all screen in app
$default-background-color: #FAFAFA;

// logo
[svgicon="brand"] { // sidebar
    .brand-background {
        fill: map_get($mat-fusedark, 500); //primary
    }
}
[svgicon="brand_square"] { // login
    .brand-text-ia {
        color: map_get($mat-fusedark, 500); //primary
    }
    .brand-background {
        fill: map_get($mat-white, 500); //primary
    }
}
$gradient-ubolino-blue: linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);
$gradient-ubolino-blue-horizontal: linear-gradient(90deg, #83DFFF 0%, rgba(255, 255, 255, 0) 100%);

%gradient-ubolino-blue {
    background: linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

// color complement
$secondary-c: map_get($md-bluedark, 500);
$primary-c: map_get($primary, 500);
$accent-c: map_get($accent, 500);
$black-c: map_get($mat-black, 500);
$white-c: map_get($mat-white, 500);


// icon
$mat-icon-default-color: map_get($mat-black, 500);
$mat-icon-default-contrast-color: map_get($mat-white, 500);

/* *********************************************************************************/
/* ********** CORE *********** CORE *************** CORE **************** CORE ******/

/* ******************************* SCROLLBAR ****************************************/
$perfectscrollbar-default-background: mat.get-color-from-palette($accent);
$perfectscrollbar-hover-background: $perfectscrollbar-default-background;
$perfectscrollbar-hover-width: 11px;
/* ******************************* BUTTON ****************************************/
$button-mat-fab-background-color: inherit;
$button-mat-fab-dimension: 65px;

$button-mat-fab-stroke-color: map_get($accent, 500);


/* ******************************* FORM FIELDS ****************************************/
$form-field-label-font-size: '14px';
$form-field-input-color: $default-font-color;
$form-field-input-label-color: $default-font-color;
$form-field-input-label-color-focused: $default-font-contrast-color;
$default-input-height: 30px;
$form-field-input-font: 500 12px/36px #{$font-family};
$default-input-line-height: $default-input-height;
$form-field-label-font: 500 #{$form-field-label-font-size}/16px #{$font-family};
$form-field-label-text-transform: "";
$form-field-label-top: 0;
$form-field-background-color: rgba(0, 0, 0, 0.12);
$form-field-underline-background-color: rgba(0, 0, 0, 0.4);
$form-field-underline-bottom: 10px;

$form-field-flex-border: 1px solid $default-font-color;
$form-field-flex-border-top: none;
$form-field-flex-border-bottom: none;
$form-field-flex-border-left: none;
$form-field-flex-border-right: none;
$form-field-ripple-background: mat.get-color-from-palette($accent);
$form-field-infix-padding: '0 5px 5px 5px';
$form-field-infix-line-height: 2em;
$form-field-margin-bottom: 0.5em;
$form-field-select-panel-background-color: map_get($mat-white, 500);


$mat-form-field-checkbox-color: rgba(0, 0, 0, 0.5);
$mat-form-field-checkbox-background-color: map_get($mat-white, 500);
$mat-form-field-checkbox-border-radius: 4px;

$mat-form-field-checkbox-color-checked: mat.get-color-from-palette($accent);;
$mat-form-field-checkbox-background-color-checked: mat.get-color-from-palette($accent);

$mat-form-field-option-color: rgba(0, 0, 0, 0.5);

/* ******************************* NAVBAR SIDEBAR ****************************************/
/* *** navbar-header *** */
// Couleur de fond
$navbar-content-background-color: $secondary-c;
$navbar-header-background-color: 'transparent';
$navbar-header-box-shadow: 'none';
$navbar-header-icon-dimensions: 32px;
$navbar-header-icon-svg-color: map_get($mat-white, 500);
// Dimension du logo
$logo-icon-svg-color: mat.get-color-from-palette($mat-white, 500);
$logo-icon-svg-width: 158px;
$logo-icon-svg-height: 38px;

// Couleurs des élements textuels et icones
$nav-font-color: $default-font-contrast-color;
$nav-link-title-color: map_get($md-blue, 500);
$nav-link-title-color-active: map_get($mat-white, 500);
$nav-icon-color: map_get($md-blue, 500);

$nav-link-icon-dimension: 26px;
$nav-link-icon-font-size: $nav-link-icon-dimension;
$nav-link-icon-width: $nav-link-icon-dimension;
$nav-link-icon-height: $nav-link-icon-dimension;
$nav-link-icon-width: $nav-link-icon-dimension;
$nav-link-icon-height: $nav-link-icon-dimension;
$nav-link-icon-line-height: $nav-link-icon-dimension;
$nav-link-icon-color-active: map_get($mat-white, 500);
$nav-link-icon-color-hover: map_get($primary, 300);

/* ******************************* TABLE ****************************************/
$table-thead-background-color: map_get($primary, 500);
$table-thead-font-color: $default-font-contrast-color;
$table-thead-checkbox-color: $default-font-contrast-color;
$table-thead-border: 1px solid rgba(map_get($primary, 500), 0.5);
$table-thead-border-radius: 5px;
$table-icon-stroke-color: map_get($accent, 500);
$table-icon-stroke-color-hover: map_get($accent, 300);
$table-icon-stroke-inactive-color: #C4C4C4;





/* *********************************************************************************/
/* ****** MODULES ********** MODULES *********** MODULES ************ MODULES ******/

/* ******************************* AUTHENTICATION ****************************************/
//fuse-login and register
$fuse-authentication-padding: '32px 32px 68px 32px ';

$fuse-authentication-color: #00e998;
$fuse-authentication-field-input-font: 700 14px/32px "Lato", "Gilroy", sans-serif;
$fuse-authentication-field-input-color: $default-font-color;
$form-field-label-height: 1em;
$fuse-authentication-field-label-color: $default-font-contrast-color;
$fuse-authentication-field-infix-line-height: 32px;//'2em';
$fuse-authentication-field-infix-padding: '0 15px 0 15px';
$fuse-authentication-background: none;

$fuse-authentication-link-decoration: "underline";
$fuse-authentication-title-font: 700 22px/50px #{$font-family};
$fuse-authentication-title-letter-spacing: "0.25px";

$fuse-authentication-button-width: 'auto';
$fuse-authentication-button-border-radius: '1.5em';
$fuse-authentication-button-margin: '0';
$fuse-authentication-button-padding: '0';
$fuse-authentication-button-radius: '1.5em';
$fuse-authentication-button-background: #17D0AD;
$fuse-authentication-button-color: white;
$fuse-authentication-button-font: 700 14px/16px "Gilroy", "Lato", sans-serif;
$fuse-authentication-button-text-transform: '';

// login-form
$fuse-authentication-login-width: 100%;
$fuse-authentication-logo-width: 100%;
$fuse-authentication-logo-margin: '0';
$fuse-authentication-title-margin: '80px 0 60px 0';

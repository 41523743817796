@use '@angular/material' as mat;




.disable-video-controls {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

app-generic-activity {

    button.mat-fab:not([class*=mat-elevation-z]) {
        box-shadow: none;
    }
    button.add-page.mat-fab.mat-accent  {
        background: #FFB08F !important;
        .mat-icon {
            transform: scale(2.5);
        }
        path {
            fill: mat.get-color-from-palette($accent);
        }
    }
    .add-page-label {
        margin-bottom: 20px !important;
    }

    .activity-bar {
        background: #1A1A4E;
        border-radius: 100px;
        color: #FFFFFF;
        height: 70px;
        display: flex;
        place-content: center;
        align-items: center;
        flex-direction: row;
        padding: 0 15px 0;
        margin-bottom: 15px;

        .left {

            svg path {
                fill: white;
                stroke: white;
                color: white;
            }

            button.mat-fab:not([class*=mat-elevation-z]) {
                background-color: transparent;
            }

        }

        .middle {
            flex-grow: 1;
            margin: 0 20px;

            .inject-middle {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                font-weight: bold;
                font-size: 20px;

                span {
                    margin-right: 10px;
                    color: map-get($mat-white, 500);
                }


                .mat-icon {
                    margin-right: 17px;
                    background-color: white;
                    border-radius: 2em;
                    color: mat.get-color-from-palette($accent);
                    padding: 9px;
                }
            }
        }

        .right {
            button {
                background-color: white;

                .mat-icon {
                    color: map-get($accent, 500) !important;

                    svg path {
                        stroke: map-get($accent, 500) !important;
                    }
                }
            }


        }

        .left, .middle {
            mat-icon {
                width: 40px;
                height: 40px;
            }
        }

    }
}

theme_modules-activities-113, .flex-container-button {
    display: flex;
    justify-content: center;
    padding: 20px;
}
// Modale dialog
//* Default dialog placeholder properties
//* Include default inner dialog scope
//* Modules

// Implement default dialog property on general dialog tag

mat-dialog-container.mat-dialog-container {
    padding: 0;
    overflow: auto;
    border-style: solid;
    position: relative;


    @include media-breakpoint("xs") {
        width: 100%;
    }

    @include media-breakpoint-up("xs") {
        min-width: 300px;// before it was 400px too big for mobile
    }

    app-edit-lesson-dialog {
        .mat-form-field-infix {
            min-height: 44px;
        }
    }

    app-dialog {
        display: flex;
        flex-direction: column;
        height: 100%;

        .activities-dialog-title {
            flex: 0 0 auto;
        }

        .mat-dialog-content {
            max-height: initial;
            flex: auto;

            &.ps--active-x > .ps__rail-x,
            &.ps--active-y > .ps__rail-y {
                opacity: 0.5;
            }
        }

        .mat-dialog-actions {
            flex: 0 0 auto;
        }
    }

    .mat-dialog-title {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        flex: 1;
        min-height: 64px;
        align-items: center;
        padding: 10px 0 10px 24px;

        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
            margin: 0 24px 0 0;
        }

        button.close {
            margin-left: auto;
        }
    }

    .mat-dialog-content {
        // main section
        margin: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        flex-direction: column;
        min-width: 40vw;
    }

    .mat-dialog-actions {
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        max-height: 100%;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 24px;
        margin: 0;
    }

    .dialog-content-wrapper {
        max-height: 85vh;
        display: flex;
        flex-direction: column;
    }
}


app-resource-upload-modal-step1,
app-resource-upload-modal-step2 {
    .content {
        .submit-button {
            font-size: 20px;
            background-color: #dfb900;
            color: #fff;
            opacity: 1;
            border-radius: 20px;

            &:hover {
                opacity: 0.7;

                .mat-button-focus-overlay {
                    background-color: #d5af00;
                }
            }

            .level1.dashboard & { // TODO à déplacer dans thème (savanturiers)
                background-color: #aa176f;
            }

            .level1.projects & { // TODO à déplacer dans thème (savanturiers)
                background-color: #305792;
            }

            .level1.corpus & { // TODO à déplacer dans thème (savanturiers)
                background-color: #dfb900;
            }

            .level1.research-sheet & { // TODO à déplacer dans thème (savanturiers)
                background-color: #ff787d;
            }

            .level1.outil & { // TODO à déplacer dans thème (savanturiers)
                background-color: #00e998;
            }

            .level1.params & { // TODO à déplacer dans thème (savanturiers)
                background-color: #305792;
            }
        }
    }
}

.activities-list-dialog {
    .activities-dialog-title {
        align-content: center;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding: 8px 20px;
    }

    .mat-dialog-container {
        border-radius: 0;
        padding: 2% 5%;

        flex: 1;
        display: flex;

        app-dialog {
            display: flex;
            flex-direction: column;
            flex: 1;

            .mat-dialog-content {
                display: flex;

                > * {
                    flex: 1 1 0%;
                }

            }
        }

        > app-dialog > .mat-card {
            @include mat-elevation(0);
        }

        .mat-dialog-actions {
            border: none;

            .btn-previous,
            .btn-next {
                display: none;
            }
        }
    }
}


.mat-dialog-content video,
.mat-dialog-content img {
    display: block;
    margin: auto;
    max-width: 102vh;
}

.mat-dialog-container .mat-dialog-title .mat-toolbar-row {
    white-space: inherit;
}

.mat-dialog-content {
    padding: 0;

    p {
        padding: 0 24px;
    }
}

/* ressouce modal video wrapper */
.videoWrapper { // TODO chercher à cibler conteneur modale (nécessite rework ConfirmDialog)
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.videoWrapper iframe { // TODO refacto
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.mat-menu-panel {

    &.form-activities-menu,
    &.lesson-activities-dialog {
        max-width: none;
        min-width: 350px;
        border-radius: 10px;
        margin-right: 80px;

        .mat-menu-content {
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: row;
            padding: 0;
            margin: 0;

            .mat-menu-item {
                display: flex;
                flex: 1 1 210px;
                flex-direction: column;
                justify-content: flex-start;
                min-height: inherit;
                height: auto;
                text-align: center;
                padding: 16px 8px 8px 8px;

                .mat-icon {
                    margin: 0 auto;
                }

                button {
                    margin: 0 auto 8px;
                }

                > span {
                    font-size: 14px;
                    line-height: initial;
                    white-space: pre-line;
                }
            }
        }
    }

    &.lesson-activities-dialog {
        .mat-menu-content .mat-menu-item .mat-icon {
            margin: 10px auto;
        }
    }
}

// BUTTON CSS GENERAL AND COLOR
.cdk-overlay-container {
    .mat-dialog-container video {
        width: 100%;
    }

    /* dialog box modal */
    .close-button {
        float: right;
    }

    .upload-dragndrop {
        border: 1px dashed #4a4a4a !important;
        border-radius: 0 !important;
        padding: 50px 50px 100px;
        color: #4a4a4a;
    }

    .upload-dragndrop .content {
        color: #4a4a4a;
        text-align: center;
    }

    .mat-icon-button.close {
        background-color: #fff;
    }

    .mat-button:hover {
        path {
            fill: #fff;
        }

        svg {
            fill: white;

            &#Calque_1 {
                color: #00e998;
                fill: white;
            }

            path {
                color: #00e998;
                fill: white;
            }
        }

        #Calque_1 {
            color: #00e998;
            fill: white;
        }
    }
}

// spinner during file upload
.example-loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 56px;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.upload-zone {
    width: 100%;
    height: 150px;
    border: 4px black dashed;
}


.box__dragndrop,
.box__uploading,
.box__success,
.box__error {
    display: none;
}

.box.has-advanced-upload {
    background-color: white;
    outline: 2px dashed black;
    outline-offset: -10px;
}

.box.has-advanced-upload .box__dragndrop {
    display: inline;
}

file-drop .upload-dragndrop {
    display: flex;
    justify-content: center;
    padding: 70px !important;
    margin: 20px 0 0;

    .content {
        flex-direction: column-reverse;
        // width: 60%;
    }
}

.corpus-upload .content {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: large;
}

.content-url-import {
    //@extend .content; // TODO fix this
    height: 100%; // keep from other .content
    flex: 1 1 auto; // keep from other .content
    min-width: 0; // keep from other .content
    margin-left: 25px; // keep from other .content
    padding-right: 50px; // keep from other .content

    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    text-align: center;

    em.mr-auto {
        font-size: initial;
        font-weight: initial;
    }
}

.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 125px;
}

app-dialog-event {
    .container {
        &.learner {
            --base-color: #E88224;
            --base-color-transparent: #E8822480;
        }

        &.trainer {
            --base-color: #C94500;
            --base-color-transparent: #C9450080;
        }

        &.manager {
            --base-color: #ababab;
            --base-color-transparent: #ababab80;
        }
    }

    .title {
        background-color: var(--base-color) !important;

        &.homework {
            background-color: #24B7C7 !important;
            --base-color-transparent: #24B7C780;
        }

        &.assessment {
            background-color: #006D86 !important;
            --base-color-transparent: #006D8680;
        }

        color: #fff;

        .close-button {
            margin-right: 24px;

            .close-icon {
                color: #fff;

                svg {
                    fill: #fff;

                    path {

                        fill: #fff;
                    }
                }
            }
        }
    }

    .full-width {
        display: flex;
        align-items: flex-end;

        &.field-title {
            align-items: flex-start;

            .icon-container {
                padding: 0.5em;
                border-radius: 50%;
                height: 48px;
                width: 48px;
                margin: 7.75px 0 7.75px 7.75px;

                &.assessment {
                    background-color: #006D86;
                }

                &.homework {
                    background-color: #24B7C7;
                }

                mat-icon {
                    height: 36px;
                    width: 36px;
                    color: #fff;

                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
            }
        }

        mat-form-field {
            flex: 1;
        }
    }

    .field-date {
        padding-right: 10px;
    }

    .field-time {
        padding-left: 10px;
    }

    .added-margin {
        margin-bottom: 1.25rem;
    }

    .mat-raised-button {
        background-color: #24B7C7;
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;

        &[disabled][disabled] {
            background-color: rgba(0, 0, 0, 0.12);
        }
    }

    // default spacing between fields in agenda for member component
    app-members-filter {
        .mat-form-field {
            margin-right: 16px;
            max-width: 300px;
        }
    }
}

.wide-dialog {
    width: 80% !important;
    min-width: 80% !important;
    max-width: 80% !important;
    position: absolute !important;
    top: 5% !important;
    bottom: 5% !important;

    mat-dialog-container.mat-dialog-container {
        width: 100% !important;
        max-width: 100% !important;
        max-height: inherit !important;

        mat-dialog-content.mat-dialog-content {
            max-height: 75vh;

            iframe {
                min-height: inherit;
                height: 67vh;
                width: 70vw;
            }

        }
    }
}

.toast-dialog {
    position: absolute !important;
    top: 1%;
    right: 1%;

    .mat-dialog-title {
        color: black;
    }

    mat-dialog-container.mat-dialog-container {
        width: 100% ;
        max-width: 100%;
        max-height: inherit;
        border-radius: 25px;
        border: none;
    }
}

.fullsizeImage{
    .is-picture{
        height: 79vh;
    }
    .action-buttons{
        display: none;
    }
    mat-dialog-container.mat-dialog-container.mat-dialog-container, MAT-DIALOG-CONTAINER.mat-dialog-container.mat-dialog-container{
        max-width: 100% !important;
    }
    .mat-dialog-content img {
        display: block;
        width: auto;
        max-height: 100%;
        max-width: 100%
    }
    .mat-dialog-content {
        max-height: 80vh;
    }

    mat-dialog-container.mat-dialog-container.mat-dialog-container, MAT-DIALOG-CONTAINER.mat-dialog-container.mat-dialog-container {
        padding-bottom: 0;
    }
    mat-dialog-container.mat-dialog-container .mat-dialog-content, MAT-DIALOG-CONTENT {
        padding-bottom: 0;
    }
}

#consigneImg{
    text-align: center;
    display: inline-block;
    position: relative;
    max-height: 79vh !important;
    .action-buttons{
        color:#005D92;
        position: absolute;
        bottom: 20px;
        right: 25px;
        text-align: right;
    }
}
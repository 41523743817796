mat-expansion-panel.mat-expansion-panel {
    background-color: transparent;
    // mat-icon rotate
    &.mat-expansion-panel:not(.mat-expanded) {
        .mat-expansion-panel-header .mat-icon {
            transform: rotate(270deg);
        }
    }
    &.mat-expansion-panel.mat-expanded {
        position: relative;
        width: 100%;
        .mat-expansion-panel-header .mat-icon {
            transform: rotate(-0deg);
        }
    }

    .mat-expansion-panel-spacing {
        margin-top: 0;
    }

    // HEADER //
    .mat-expansion-panel-header,
    .mat-expansion-panel-header.mat-expanded:hover {
        @extend %button-image;
        @extend %button-big;
        @extend %button-image-big-blue;

        height: auto !important;

        &.lineText2, &.lineText3, &.lineText4 {
            @extend %button-image-big-blue-double;
        }

        mat-panel-title.mat-expansion-panel-header-title {
            @extend %button-image-title;
        }

        .mat-icon {
            @extend %button-image-icon;
            svg {
                @extend %button-image-icon-svg;
            }
        }
    }

    // CONTENT //



}

app-assignments-list mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header,
app-assignments-list mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header.mat-expanded,
app-assignments-list mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header.mat-expanded:hover {
    @extend %button-image-big-orange;
    &.lineText2, &.lineText3, &.lineText4 {
        @extend %button-image-big-orange-double;
        line-height: 1.2em;
    }
}

.mat-expansion-panel-body {
    padding: 20px 24px 16px;
}
.mat-expansion-panel-body,
.mat-expansion-panel-body,
.mat-expansion-panel-content {
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
}
.mat-expansion-panel-content {
    width: 84%;
    margin: 0 8%;
}
.mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none;
    &:before{
        position: absolute;
        z-index: -1;
        top: 30px;
        left: 8%;
        content: '';
        display: block;
        width: 84%;
        margin-left: auto;
        margin-right: auto;
        height: calc(100% - 30px);
        color: #005D92;
        background: #e6f1f9;
        border-radius: 0 0 15px 15px;
        border: 2px solid #ddedfa;
    }
    &:not(.mat-expanded):before {
        height: 10px;
        transition: height ease 0.2s;
    }
}

app-assignments-list .mat-expansion-panel:not([class*='mat-elevation-z']) {
    &:before{
        background: rgba(255, 166, 21, 0.1);
        border: 2px solid rgba(255, 166, 21, 0.15)
    }
}

app-chapters-list {
    .mat-accordion {
        display: block;
        padding-bottom: 175px;
    }
    .mat-expansion-panel-header .mat-icon {
        @extend %svg-icon-rounded;
        color: #0FA9FF;
    }
}
app-assignments-list {
    .mat-expansion-panel-header .mat-icon {
        @extend %svg-icon-rounded;
        color: #FFA615;
    }
}

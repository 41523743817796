@use '@angular/material' as mat;
.hide {
    display: none !important;
}

%activities-card-success-infos {
    .missingReply, .missingResponse::before, .answerMissed .msg-icon,
    .correctReply, .right::before, .correctAnswer .msg-icon,
    .wrongReply, .wrong::before, .wrongAnswer .msg-icon,
    .validated, .validated::before, .validated .msg-icon,
    .answerMissed .mat-radio-container, .answerMissed .mat-checkbox-inner-container,
    .correctAnswer .mat-radio-container, .correctAnswer .mat-checkbox-inner-container,
    .wrongAnswer .mat-radio-container, .wrongAnswer .mat-checkbox-inner-container,
    .validated .mat-radio-container, .validated .mat-checkbox-inner-container {
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: 20px center;
    }

    .validated, .validated::before, .validated .msg-icon,
    .validated .mat-radio-container, .validated .mat-checkbox-inner-container {
        background-image: icon('save_white.svg');
        background-color: $validated-color;
    }

    .missingReply, .missingResponse::before, .answerMissed .msg-icon,
    .answerMissed .mat-radio-container, .answerMissed .mat-checkbox-inner-container {
        //background-image: icon('step_task_white.svg');
        background-color: #ED6723;
    }

    .correctReply, .right::before, .correctAnswer .msg-icon,
    .correctAnswer .mat-radio-container, .correctAnswer .mat-checkbox-inner-container {
        //background-image: icon('check_white.svg');
        background-color: $success-color;
    }

    .wrongReply, .wrong::before, .wrongAnswer .msg-icon,
    .wrongAnswer .mat-radio-container, .wrongAnswer .mat-checkbox-inner-container {
        //background-image: icon('close_white.svg');
        background-color: $error-color;
    }

    .mat-card-actions {
        button:disabled, button[disabled] {
            &.registered {
                background-color: $success-color;
                color: white;

                .mat-icon path {
                    fill: white;
                }
            }
        }
    }

    .mat-mini-fab {
        &.success {
            background-color: $success-color;
        }

        &.warning {
            background-color: $warning-color;
        }

        &.error {
            background-color: $error-color;
        }

        &.check {
            background-color: $validated-color;
        }

        .mat-icon {
            color: #fff;
        }

        path {
            fill: #fff;
        }
    }
}

%activities-card {
    height: 100%;
    margin: 0;
    flex: 1 1 100%;
    align-content: stretch;
    justify-content: flex-start;

    margin-bottom: 0;
    padding-bottom: 0;

    // Elements singletons transverses

    p {
        margin: 0 0 15px 0;
    }

    img {
        &.formula {
            max-width: none;
            max-height: none;
            display: inline-block;
            vertical-align: middle;
        }
    }

    // reset user agent stylesheet
    table, caption, tbody, tfoot, thead, tr, th, td {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }

    table {
        display: table;
        border-collapse: collapse;
        border-spacing: 0;
        width: auto;

        caption {
            display: table-caption;
        }

        thead {
            display: table-header-group;
        }

        tbody {
            display: table-row-group;

            &::before, &::after {
                display: none;
            }
        }

        tfoot {
            display: table-footer-group;
        }

        tr {
            display: table-row;
            box-shadow: none;
        }

        th, td {
            display: table-cell;
        }

        p {
            display: block;
            margin: 0;
            padding: 0;
        }
    }

    textarea {
        border: 3px solid #979797;
        padding: 10px;
        width: 60%;
        margin: 0 20%;
        min-height: 150px;
    }

    @extend %activities-card-success-infos;


    // *** HEADER ***

    .mat-card-header {
        flex: none;

        .mat-card-header-text {
            /* CSS styles go here */
            margin: 0 !important; // for example to remove the margin
            width: 100%;

            .mat-card-title {
                width: 100%;
            }
        }

        app-form-player & {
            display: none;
        }
    }


    // *** CONTENT ***

    .mat-card-content {
        display: flex;
        flex: 1 100%;
        flex-direction: column;
        overflow-y: auto;
    }

    // *** ACTIONS ***

    .mat-card-actions, &.mat-card-actions,
    .mat-dialog-actions, &.mat-dialog-actions,
    .user-action-btn {
        justify-content: center;
        text-align: center;
        margin: 0 5px 0 12px;
        flex-direction: row;

        .mat-button + .mat-button,
        .mat-button + .mat-raised-button,
        .mat-raised-button + .mat-button,
        .mat-raised-button + .mat-raised-button {
            margin-left: 15px;
        }

        .reprendre-btn {
            margin-left: 10px;
        }

        .user-action-btn {
            display: flex;
        }

        button:disabled,
        button[disabled] {
            background-color: mat.get-color-from-palette(mat.$grey-palette, 300);

            path {
                fill: rgba(0, 0, 0, 0.26);
            }
        }
    }

    .mat-button,
    .mat-raised-button {
        .mat-button-wrapper > * {
            vertical-align: middle;
        }
    }

    mat-card-footer.mat-card-footer { // = mat-card-actions
        display: flex;
        margin: 0;
        flex: none;

        .gradePercentil {
            text-align: center;
            margin: auto;
        }


    }

}

.activities-card {
    @extend %activities-card;
}

app-dialog .activities-card {
    @extend %activities-card;
}

app-user-action-button {
    display: flex;
    flex: 1 1;
    padding: 10px 0;

    mat-card-actions.mat-card-actions {
        position: relative;
        display: flex;
        flex-direction: row;
        place-content: center;
        align-items: center;

        .mat-fab {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}


.activities-card app-consignes {
    display: flex;
    flex: 0 1 auto;
    justify-content: left;

    // to include sound icon inside blue zone when it's an insr
    .is-instruction {
        background-color: rgba(15, 169, 255, 0.1);
        border-radius: 10px;
        padding: 0 !important;

        .instruction {
            background-color: transparent !important;
        }

        mat-icon {
            margin-bottom: 15px;
        }
    }

    .wording,
    .instruction {
        font-size: 20px;
        letter-spacing: 0.7px;
        line-height: normal;
        text-align: justify;
        margin-bottom: 30px;

        img.formula {
            display: inline;
        }
    }

    .wording {
        font-weight: normal;
        color: #434445;
    }

    .instruction {
        font-weight: 600;
        color: #005D92;
    }

    .mat-button,
    mat-menu {
        display: none;
    }
}


.activities-card .answer-state-msg {
    font-weight: bold;
    font-size: 14px;
    margin: auto;
    justify-content: center;
    text-align: center;

    &.wrongreply {
        color: darkred;
    }

    &.missingreply {
        color: #ed6723;
    }
}

.activities-card .latex-wrapper {
    .renderedInputContent {
        line-height: normal;
        min-height: 30px; // firefox
        padding-top: 3px;
    }
}


// chargement des activitées dans le lecteur

.activities-list-dialog mat-card.activities-card,
#lessons mat-card.activities-card {
    overflow-y: auto; // évite que le scroll se déclanche sur les parents
    // TODO trouver pourquoi le perfectscrollbar ne joue pas son rôle sur les enfants mat-header et mat-content(pas demandé)

    .content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .wrapper {
        font-size: 18px;
        width: 100%;
        height: 72vh;
        padding: 15px 5%;
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        align-items: center;
        overflow-y: auto;

        p {
            text-align: justify;
            display: inline-block;
        }

        .center-middle {
            padding: 5px;
            display: block;
            font-size: 20px;
            text-align: center;

            img {
                display: inline-block;
                max-height: 80vh;
                margin-bottom: 25px;
            }

            .text-anchor {
                display: flex;
                flex-direction: column;
                align-content: center;
                justify-content: flex-start;
                align-items: center;
                margin: 30px auto 50px auto;
                height: 10px;
                position: relative;
                text-align: center;

                > div {
                    position: absolute;
                    min-width: 50vw;
                    text-align: center;
                    text-justify: auto;
                }
            }
        }

    }


}


.activities-card .mat-card-actions,
.activities-card.mat-card-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

}


.mat-card.activities-card { //TODO a Fusionner
    /* problème dans les parcours iboost
    //flex: 1;
    */
    flex-direction: column;
    align-items: center;

    p {
        font-size: 20px;
    }

    #lessons & {
        &.mat-card {
            height: 100%; // safari
            flex-direction: column;

        }
    }

    .latex-wrapper {
        font-size: 20px;
    }

    .keyboard-grid-container {
        font-size: 16px;
    }

    .missingReply, .missingResponse::before, .answerMissed .msg-icon,
    .correctReply, .right::before, .correctAnswer .msg-icon,
    .wrongReply, .wrong::before, .wrongAnswer .msg-icon,
    .validated, .validated::before, .validated .msg-icon,
    .answerMissed .mat-radio-container, .answerMissed .mat-checkbox-inner-container,
    .correctAnswer .mat-radio-container, .correctAnswer .mat-checkbox-inner-container,
    .wrongAnswer .mat-radio-container, .wrongAnswer .mat-checkbox-inner-container,
    .validated .mat-radio-container, .validated .mat-checkbox-inner-container {
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: 20px center;
    }

    .validated, .validated::before, .validated .msg-icon,
    .validated .mat-radio-container, .validated .mat-checkbox-inner-container {
        background-image: icon('save_white.svg');
        background-color: $validated-color;
    }

    .missingReply, .missingResponse::before, .answerMissed .msg-icon,
    .answerMissed .mat-radio-container, .answerMissed .mat-checkbox-inner-container {
        //background-image: icon('step-task_white.svg');
        background-color: #ED6723;
    }

    .correctReply, .right::before, .correctAnswer .msg-icon,
    .correctAnswer .mat-radio-container, .correctAnswer .mat-checkbox-inner-container {
        //background-image: icon('check_white.svg');
        background-color: $success-color;
    }

    .wrongReply, .wrong::before, .wrongAnswer .msg-icon,
    .wrongAnswer .mat-radio-container, .wrongAnswer .mat-checkbox-inner-container {
        //background-image: icon('close_white.svg');
        background-color: $error-color;
    }

    .mat-card-content {
        overflow: auto;
    }

    .mat-card-actions {
        button:disabled, button[disabled] {
            &.registered {
                background-color: $success-color;
                color: white;
            }
        }
    }

    .mat-mini-fab {
        &.success {
            background-color: $success-color;
        }

        &.warning {
            background-color: $warning-color;
        }

        &.error {
            background-color: $error-color;
        }

        &.check {
            background-color: $validated-color;

            [svgicon="save"] {
                width: 16px;
                height: 16px;
                min-width: inherit;
                min-height: inherit;
                line-height: 16px;
            }
        }
    }

    &.two-columns-layout {
        flex-direction: row !important; // don't remove
        flex-wrap: wrap;
        padding-bottom: 0;

        .wording,
        .instruction {
            .activities-card & {
                margin-bottom: 0;
            }
        }

        .mat-card-header {
            // column 1
            flex: 1 0 0; // grow ; don't shrink ; size of content no min
            flex-direction: row;
            margin-right: 1%;
        }

        .mat-card-content {
            // column 2
            flex: 1 0 0; // grow ; don't shrink ; size of content no min
            flex-direction: column;
            margin-left: 1%;
        }

        .answer-state-msg {
            // line under
            display: flex;
            flex: 1 100%; // grow&shrink width:100%
        }

        .mat-card-actions {
            width: 100%;
        }
    }

}
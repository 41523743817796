app-qcu,
app-qcm,
app-image-zoning {
    display: flex;

    .mat-card, .mat-card.two-columns-layout {

        .mat-card-header {

            .sub-instruction-enabled {
                flex-direction: column;

                h2 {
                    color: #005D92;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                }
            }

            app-consignes {
                .wording {
                    margin: 0;

                    img {
                        border-radius: 10px;
                    }


                }
            }
        }

        .mat-card-content app-consignes {
            .container {
                width: 100%;
            }

            .instruction {
                @extend %textCartouche !optional;

                &.is-picture {
                    background: transparent !important;
                    cursor: default;

                    img {
                        max-height: 180px;
                    }
                }
            }
        }

        // for exo with letter or word to select we apply other css
        .mat-checkbox-group:not(.is-word-or-lettersSelecting) {
            margin: 0;
            display: flex;
            flex-direction: row;
            grid-template: none;
            grid-auto-rows: inherit;
            flex-wrap: wrap;
            grid-gap: inherit;
            flex: 2;
            align-content: center;

            div.checkbox-wrapper {
                @extend %button-half;
                @extend %button-half-white;
                @extend %button-image;

                &.selected {
                    @extend %button-half-blue;
                    border: none;
                }

                border: none;
                max-width: 50%;
                padding: 0 20px 3.2% 20px;

                mat-checkbox {

                    width: 100%;
                    border: none;


                    &.selected {
                        .mat-checkbox-inner-container {

                            .mat-checkbox-label {
                                color: #FFF;
                            }

                            .mat-checkbox-frame {
                                top: calc(50% - 13px);
                                border-color: rgba(255, 255, 255, 1);
                            }

                            .mat-checkbox-background {
                                background-color: #fff;
                                width: 24px;
                                height: 24px;
                                border-radius: 4px;

                                .mat-checkbox-checkmark-path {
                                    stroke-width: 4px;
                                    stroke: #0FA9FF !important;
                                    stroke-linecap: round;
                                }
                            }
                        }

                        label.mat-checkbox-layout {
                            .mat-checkbox-label {
                                color: #FFF;
                            }
                        }

                    }

                    label.mat-checkbox-layout {
                        padding: 0 0 0 2%;

                        .mat-checkbox-inner-container {
                            flex: none;

                            .mat-checkbox-frame {
                                width: 24px;
                                height: 24px;
                                top: calc(50% - 13px);
                                border-radius: 4px;

                                border-width: 3px;
                                border-color: #0FA9FF;

                            }

                            .mat-checkbox-background {
                                top: calc(50% - 13px);
                            }

                        }

                        .mat-checkbox-label {
                            padding: 2px 0 0 5px;
                            align-items: center;
                            font-size: 14px;
                            line-height: 0.9em;
                            color: #0FA9FF;
                        }

                    }


                }
            }
        }

        .mat-card-content {
            flex: 0 1 70% !important;

            app-consignes {
                // flex: 1 1 auto;
                display: block;
                flex: none;
                //align-items: center;
                .instruction, .wording {
                    // @extend %textCartouche; TODO je ne sais pas si c'est du texte ou une image
                }
            }

            #container-for-answers-available {
                flex: 1 1 50%;
                justify-content: center;
            }

            > button.btn-option, #container-for-answers-available > button.btn-option {
                @extend %button-image;
                @extend %button-half;
                @extend %button-half-blue;
                border: none;
                margin: 0 auto 0 auto !important;
                justify-content: center;
                color: white;
                width: 50%;
                height: auto;
                padding: 0 20px 3.2% 20px;

                position: relative;
                > span {
                    position: absolute;
                    top: 41%;
                    transform: translateY(-50%);
                }

                &[disabled="true"], &.mat-raised-button[disabled][disabled] {
                    // color: rgba(255, 255, 255, 1);
                    color: white;
                }

                &.pristine { // perfect
                    //@extend %button-half-green;
                }

                &.was-correct {
                    @extend %button-half-green;
                    opacity: 0.3;
                    cursor: not-allowed;
                }

                &.incorrect {
                    @extend %button-half-grey;
                    color: white;
                }

                &.currently-correct {
                    @extend %button-half-green;
                    color: white;
                }
            }
        }
    }
}

app-qcm {
    .mat-raised-button {
        &[disabled] {
            opacity: 40%;
            cursor: not-allowed;
        }
    }

    // qcm is used to select word in sentence or to select letter in word
    //this css will overide default qcm css
    .is-word-or-lettersSelecting {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        grid-gap: 0;
        background-color: #FFFFFF;
        text-align: center;
        border-radius: 10px;
        border: solid 3px #0FA9FF;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);
        margin: 0 0 20px 0 !important;
        padding: 0;
        flex: 1;
        max-height: 140px;

        .mat-checkbox-inner-container {
            display: none !important;
        }

        .mat-checkbox {
            border: none !important;
        }

        .mat-checkbox-label {
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0;
            color: #005D92;
            padding: 3px !important;
        }

        .selected {
            .mat-checkbox-label {
                color: #0fa9ff;
            }
        }

        &.not-correct {
            border: solid 3px #565656;

            .mat-checkbox-label {
                opacity: 30%;
                color: #565656;
            }

            .selected {
                .mat-checkbox-label {
                    opacity: 100%;
                }
            }
        }

        &.correct {
            border: solid 3px #89E30C;

            .mat-checkbox-label {
                opacity: 30%;
                color: #565656;
            }

            .selected {
                .mat-checkbox-label {
                    color: #89E30C;
                    opacity: 100%;
                }
            }
        }
    }
}

app-qcm, app-qcu {
    #type-is-word-or-lettersSelecting {
        app-consignes {
            flex: 0;
            margin-bottom: auto;
        }

        mat-card-content {
            justify-content: flex-end;
            padding-bottom: 20px;
        }
    }
}


app-qcm, app-qcu {
    .read-in-progress {
        color: #0fa9ff !important;
    }
}



 table {
    width: 100%;

    th, td {
        &.mat-column-checkbox {
            width: 58px;
        }
        &.mat-column-icon {
            text-align: center;
        }
    }

    th.mat-header-cell, td.mat-cell {
        padding: 3px 8px;
    }

    th.cdk-column-consulted, td.cdk-column-consulted {
        width : 150px;
        text-align: center;
        .mat-icon {
            color: #C4C4C4;
            height: 28px;
            width: 28px;

            &.active {
                color: #FCB731;
            }
        }
    }

    tr {
        height: 56px; // ER SAV like min-height

        &:hover{
            cursor: pointer; // All
        }
        &.mat-header-row {
            height: 37px; // like min-height
        }

    }

    // Specific column
     .mat-column-expandRow {
         width: 32px;
     }

    tbody:before { // ER
        // https://stackoverflow.com/questions/9258754/spacing-between-thead-and-tbody
        content: "";
        display: block;
        height: 5px;
    }

}

app {
    overflow: auto;
}

.mat-drawer-container {
    background-color: $default-background-color !important;
}

/* .mat-drawer {
    background-color: transparent !important;
    min-width: 306px;
    box-shadow: none !important;
    overflow: hidden !important;
    height: 100%;

    .logo-icon-svg {
        width: 155px;
        height: auto;
        margin: 25px 56px;
        display: block;
    }

    .logo-close-svg {
        width: 35px;
        height: auto;
        position: absolute;
        right: 0;
        margin: 30px;
        cursor: pointer;
    }

    app-main-menu-element-generic {
        @extend %button-image;
        @extend %button-big;
        @extend %button-image-big-purple;
        background-image: url('~./assets/ubolino_app/images/buttons/btn-big-purple.png') !important;
        background-size: 100% 100% !important;
        margin-bottom: -4px;
        margin-left: 4px;
        padding: 8px 30px 5.2% 30px;

        mat-icon {
            margin-top: -10px;
        }

        app-main-menu-element-link {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        .mat-list-item-content {
            padding: 0 !important;
        }

        &:nth-child(1) {
            width: calc(100% - 45px) !important;
        }

        &:nth-child(2) {
            width: calc(100% - 52px) !important;
        }

        &:nth-child(3) {
            width: calc(100% - 57px) !important;
        }

        &:nth-child(4) {
            width: calc(100% - 65px) !important;
        }

        &:nth-child(5) {
            width: calc(100% - 77px) !important;
            position: absolute;
            bottom: 25px;
        }

        &:nth-child(6) {
            @extend %button-image-big-orange;
            width: calc(100% - 77px) !important;
            position: absolute;
        }

        &:hover, &.active {
            width: calc(100% - 4px) !important;
            transition: all .2s ease-in;
        }

        .mat-list-item, .mat-list-item:hover {
            color: white !important;
            height: 58px !important;
            margin-top: -4px;

            &:hover {
                text-decoration: none !important;
                background: none !important;
            }
        }
    }
} */

.mat-drawer:before {
    border-radius: 0 20px 0 0;
    border-left: 0 solid transparent;
    border-right: 50px solid transparent;
    border-top: 100vh solid $default-background-color;
    content: '';
    position: absolute;
    min-width: 290px;
}

.mat-drawer-inner-container {
    position: absolute;
}

mat-sidenav-container {
    &.footerVisible {
        background: imageApp('/backgrounds/footer.png') bottom no-repeat !important;
        background-size: 100% 10% !important;
        background-blend-mode: darken; // to have footer white zone taking default background color
    }
}

.position-top-lower-for-content {
    mat-sidenav-content.mat-sidenav-content {
        > .content {
            position: relative;
            top: 90px;
        }
    }
}

mat-sidenav-content.mat-sidenav-content {
    > .content {
        //display: flex;
        margin: 0 auto;
        max-width: 545px;
        padding: 0 30px 0 30px; // footer image height
        height: 100%;

        .is-login-or-register-active & { // when & is after it's like his class is before all current selector
            display: flex;
        }

    }

    &.headerVisible > .content {
        height: calc(100% - 90px); // 80px header size
    }
}

app-header {
    &.header-fixed {
        position: fixed;
        width: 100%;

        background: rgba(250, 250, 250, 0.5);
        backdrop-filter: blur(3px);
        z-index: 999;
    }

    #header {
        display: flex;
        align-items: center;
        padding: 20px 20px 20px 0;
    }

    button {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-sizing: border-box;
        box-shadow: 0 27px 40px rgba(0, 0, 0, 0.06), 0 10px 14px rgba(0, 0, 0, 0.06), 0 5px 8px rgba(0, 0, 0, 0.06), 0 3px 4px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.04), inset 0 2px 2px rgba(255, 255, 255, 0.3);

        mat-icon {
            color: #743F90;
            width: auto;
            min-height: 2rem;
        }
    }

    .btn-menu {
        padding: 2px 18px 1px 18px;
        border-radius: 0 15px 15px 0;

        &:active {
            transition: transform 1s, opacity 500ms;
            transform: scale(0.90);
            opacity: 0.75;
        }

        mat-icon {
            height: 37px;
            width: 37px;
        }
    }

    .btn-back {
        padding: 10px 15px 6px;
        border-radius: 15px;

        &:active {
            transition: transform 1s, opacity 500ms;
            transform: scale(0.90);
            opacity: 0.75;
        }
    }

    app-progress {
        margin-left: auto;
        min-width: 130px;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

/*
    fuse-profile
        #profile
            .content
                <nav
                <router-outlet
                <show-profile
                    <fuse-profile-about
                    <app-join-class
                     |  .container
                     |      .title
                     |      .list-user-class-container
                     |       |   .user-class-joined
                     |       |   button
                     |      .join-class
                     |      |    input
                     |      |    button
                    .title
                    .mat-dialog-actions
                        button
                        button

*/

fuse-profile {
    height: 100%;
    display: flex;
}

#profile {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #743F90;

    .h2 {
        padding-top: 10px;
    }

    .mat-dialog-actions button {
        width: 100%;
        display: flex;
        @extend %button-image;
        @extend %button-big;
        @extend %button-image-big-purple;
        height: auto;
        top: 0;
        border: none;
        justify-content: center;
        color: white !important;
        align-items: center;
        margin-left: 0;

        /* &:last-child{
            @extend %button-image-big-gold;
        } */
        [svgicon="logout"] {
            transform: translateY(-3px);
        }

        .mat-button-wrapper {
            padding: 0 5px 1px 10px !important;
        }

        mat-icon {
            width: 20px !important;
            height: 20px !important;
            order: 2;
        }

        span {
            color: white !important;
            display: flex;
            flex-grow: 1;
            margin-left: 0;
            margin-right: 0;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding-right: 5px;
            font-weight: 600;
        }
    }

    .edit-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .cancel {
        @extend %button-image-square-light-red;

        .mat-button-wrapper {
            padding: 0px 5px 8px 8px !important;
        }
    }

    .validate {
        @extend %button-image-square-cyan;
        margin-right: 5px !important;

        .mat-button-wrapper {
            padding: 0px 5px 8px 8px !important;
        }
    }

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #743F90;
        padding: 30px 15px 15px;

        &:first-child {
            padding: 15px;
        }
    }

    .mat-tab-header {
        display: none;
    }

    .mat-accent-bg {
        background: none !important;
    }

    fuse-profile-about .profile-box {
        margin-bottom: 0;
        box-shadow: none;
    }

    mat-card-actions {
        padding: 16px;
    }

    fuse-edit-profile-forms button {
        background-color: #17D0AD;
        color: white;
        font: 700 14px/16px Gilroy, Lato, sans-serif;
        padding: 14px 16px 11px 16px;
        border-radius: 1.5em;
        width: auto;
        margin-bottom: 3px;
    }

    .button-container {
        margin: 21px 0 auto;
    }

    mat-card-actions button, fuse-edit-profile-forms .submit-button {
        @extend %button-image;
        @extend %button-big;
        @extend %button-image-medium-yellow;
    }

    header {
        padding: 0;
        margin-bottom: 0;
    }

    .h2 {
        font-size: 25px;
    }

    fuse-profile-about .profile-box .content {
        padding: 0;
    }

    app-join-class {
        .container {
            display: flex;
            flex-direction: column;
            padding-top: 20px;
        }

        .list-user-class-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: center;

            &:first-child {
                padding-top: 20px;
            }

            button.btn-delete {
                @extend %button-image-square-light-red;
                width: 67px;
                min-width: 67px;
                padding-top: 10px;
            }

            .user-class-joined {
                @extend %button-image;
                @extend %button-wide;
                @extend %btn-image-medium-purple-reverse;
                border: none;
                margin: 0 4px 0 8px !important;
                justify-content: flex-start;
                color: white;
                height: auto;
                cursor: default;
                padding: 3px 0 0 23px;
                max-width: 250px;
                font-size: 18px;

                &::before {
                    padding-top: calc(100% / 3.8);
                }

                &:active {
                    transition: none;
                    transform: none;
                    opacity: 1;
                }
            }
        }

        .join-class {
            display: flex;
            margin-top: 5px;
            align-items: center;
            width: 100%;
            justify-content: center;
            padding-left: 13px;

            input {
                @extend %button-image;
                @extend %button-medium;
                @extend %btn-image-medium-purple-empty;
                border: none;
                justify-content: center;
                color: #743f90;
                cursor: default;
                background-position: -4px 0;
                max-width: 250px;
                padding: 0 5px 0 15px !important;
                text-align: left;
                margin: 0 !important;
                height: 64px;
                font-size: 14px !important;
                font-weight: 600;

                &::before {
                    padding-top: calc(100% / 3.8);
                }

                &:active {
                    transition: none;
                    transform: none;
                    opacity: 1;
                }
            }

            input::placeholder {
                color: #743f90 !important;
            }

            button {
                @extend %button-image-square-cyan;
                width: 67px;
                min-width: 67px;
                padding-top: 10px;
                margin: 0;
            }
        }
    }
}

.placeholder {
    margin: auto;
    display: block;
    padding-top: 50px;
}

#profile show-profile {
    .mat-dialog-actions {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
            max-width: 330px;
            padding: 0 20px 5px 20px !important;

            span {
                overflow: hidden;
                white-space: normal;
                height: 30px;
                text-align: left;
            }
        }

        button.btn-logout {
            .mat-icon {

            }
        }

        button.btn-delete {
            @extend %button-image-wide-purple;
        }
    }

}

#profile fuse-profile-about {
    header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            @extend %button-image;
            @extend %button-half-square;
            @extend %button-image-square-light-blue;
            padding-top: 5px;
            width: 67px;
            min-width: 67px;
            height: auto !important;
            padding-bottom: 20px;
            min-height: 52px;
            background-size: 100% !important;

            .mat-button-wrapper {
                padding: 5px 5px 1px 6px !important;
            }

            mat-icon {
                width: 30px !important;
                height: 30px !important;
            }
        }

        .delete {
            @extend %button-image-square-light-red;

            .mat-button-wrapper {
                padding: 0 8px 0 8px !important;
            }
        }
    }

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #743F90;
    }

    .content {
        display: block !important; // why????
        background: #FFFFFF;
        border: 1px solid #F2F2F2;
        box-sizing: border-box;
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        margin: 0 10px;
    }

    .info-line {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: #743F90;
        line-height: 100%;
        margin-bottom: 0 !important;
        border-bottom: solid 1px #F2F2F2;
        padding: 15px;

        &:last-child {
            border: none;
        }

        .title {
            font-size: 14px;
            padding: 0;
        }

        .info {
            font-size: 16px;
            padding: 0
        }
    }
}

app-continue-bar .wrapper {
    padding-bottom: 0;
}

fuse-edit-profile-forms {
    .edit-header {
        padding-top: 10px !important;
    }

    .h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px !important;
        line-height: 24px;
        color: #743F90;
    }

    .username {
        border-radius: 12px;

        mat-form-field {
            display: flex;

            .mat-form-field-wrapper {
                flex: 1;

                .mat-form-field-underline {
                    display: none;
                }

                .mat-form-field-flex {
                    display: flex;
                    padding: 12px 10px 0 10px;

                    .mat-form-field-infix {
                        padding: 15px;
                        display: flex;
                        height: 100%;
                        flex-direction: row-reverse;
                        background: #FFFFFF;
                        border: 1px solid #F2F2F2;
                        box-shadow: 0 6px 5px rgba(0, 0, 0, 0.01), 0 3px 2px rgba(0, 0, 0, 0.01);
                        border-radius: 12px;

                        input {
                            flex: 1;
                            height: 100%;
                            margin: 0;
                            text-align: right;
                        }

                        span {
                            display: flex;
                            flex: 1;
                            position: relative;
                            padding: 0;
                            top: 0;
                            min-height: 100%;
                            height: 100%;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 19px;
                            text-align: right;
                            color: #743F90;

                            label {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                color: #743F90;
                                display: contents;

                            }
                        }
                    }


                }
            }
        }
    }
}


app-what-is-missing, app-what-is-missing-audio {
    app-consignes {
        mat-icon {
            background: #F9F9FF;
            border: 2px solid #B4BAEF;
            box-shadow: 0 2px 0 #b4baef;
            border-radius: 2px;
            width: 48px !important;
            height: 40px !important;
            padding: 5px;
        }
    }

    #container-for-answers-available > div {
        margin-bottom: 0 !important;
    }

    #container-for-answers-available, #container-for-answers-available-text-version {
        // use to make front and back of the card that will be returned with effect
        .card-container {
            box-shadow: none !important;
            transform: rotateY(0deg); // value to change to return the card
            backface-visibility: hidden;

            .question-container {
                mat-icon {
                    transform: rotateY(180deg); // apply on mat-icon because if apply on question-container it will be hide
                }
            }

            img {
                backface-visibility: hidden;
            }
        }

        .step-1, .step-2.to-hide {
            height: 100%;
            background-color: #B4BAEF;
        }

        .step-0, .step-2.not-to-hide {
            mat-icon {
                position: absolute;
            }
        }

        .step-0, .step-2.not-to-hide {
            animation: front; // turn card on front
            animation-duration: 500ms;
            animation-fill-mode: forwards;
        }

        .question-container {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #B4BAEF;
            width: 100%;
            height: 100%;
        }

        mat-icon {
            background-color: #B4BAEF;
            width: auto !important;
            height: auto !important;
            padding: 0 !important;
            color: #0010A7 !important;

            svg {
                padding: 24%;
            }
        }

        .step-1, .step-2.to-hide {
            animation-name: back; // return the card
            animation-duration: 500ms;
            animation-fill-mode: forwards;
        }

        @keyframes back {
            from {
                transform: rotateY(0deg);
                transform-style: preserve-3d;
            }
            to {
                transform: rotateY(180deg);
                transform-style: preserve-3d;
            }
        }

        @keyframes front {
            from {
                transform: rotateY(180deg);
                transform-style: preserve-3d;
            }
            to {
                transform: rotateY(0deg);
                transform-style: preserve-3d;
            }
        }
    }

    .step-1.to-hide, .step-1.not-to-hide, .step-2.to-hide {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;

        mat-icon {
            position: absolute !important;
            width: 50% !important;
            height: auto !important;
        }
    }

    mat-card-content {
        margin-left: auto !important;
        margin-right: auto !important;

        .available-answers {
            background: #dee1ff !important;

            div {
                border: 2px solid #B4BAEF !important;
                border-radius: 2px !important;
            }
        }

        .btn-tts {
            background: #F9F9FF !important;
            border: 2px solid #B4BAEF !important;
            border-radius: 2px !important;
            width: auto !important;
            height: auto !important;

            mat-icon {
                color: #0018FF !important;
                width: 24px !important;
                height: 24px !important;
                margin: 4px 10px !important;
            }
        }
    }

    mat-card-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 30px 0 30px;

        button {
            background: #1E35FC !important;
            border: 2px solid #0010A7;
            box-shadow: 0px 3px 0px #0010a7;
            border-radius: 2px;
            width: 45%;
            margin-left: 0 !important;
            margin-bottom: 10px;

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                letter-spacing: 0.005em;
            }
        }

        .btn-next {
            width: 100%;
        }
    }
}

app-what-is-missing,
app-what-is-missing-audio {
    mat-card-footer {
        .btn-select-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
                padding: 10px;
            }

            mat-icon {
                margin-left: 10px;
            }
        }

        .btn-disabled {
            opacity: 65%;
            cursor: not-allowed;
        }

        .answer-is-correct {
            background: #00957A !important;
            border-color: #00C29F !important;
            box-shadow: 0 3px 0 #00C29F !important;
        }

        audio {
            display: none;
        }
    }
}

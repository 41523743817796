
// row space-around center

/****************************************/
/*                                      */
/*      A       B       C       D       */
/*                                      */
/****************************************/

%fxRowSpaceAroundCenter { // in HTML > fxLayout="row" fxLayoutAlign="space-around center"
    height: 100%;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    place-content: center space-around;
}

/****************************************/
/*                                      */
/*A           B            C           D*/
/*                                      */
/****************************************/

%fxRowSpaceBetweenCenter {
    height: 100%;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;

    place-content: center space-between;
    align-items: center;
}

/****************************************/
/*                                      */
/*ABCD                                  */
/*                                      */
/****************************************/

%fxRowStartCenter {
    height: 100%;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;

    place-content: center flex-start;
    align-items: center;
}


/****************************************/
/*A           B            C           D*/
/*A           B            C           D*/
/*A           B            C           D*/
/****************************************/

%fxRowSpaceBetweenStretch {
    height: 100%;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;

    place-content: stretch space-between;
    align-items: stretch;
    max-height: 100%;
}





/******************/
/*AAAAAAAAAAAAAAAA*/
/*BBBBBBBBBBBBBBBB*/
/*CCCCCCCCCCCCCCCC*/
/*                */
/*                */
/******************/

%fxColStartStretch {
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;

    place-content: stretch flex-start;
    align-items: stretch;
    max-width: 100%;
}

body.summary .mat-sidenav-content {
    background-color: #FAFAFA;

    > .content {
        padding: 0;
        margin: 0 auto;
    }
}

body.summary mat-sidenav-content.mat-sidenav-content.footerVisible > .content {
    padding: 0;
}

body.summary .mat-drawer-container {
    padding: 0;
}

body.summary fuse-app-lessons #lessons app-stepper-wrapper > .page-layout > content {
    height: auto;
}

body.summary fuse-app-lessons #lessons app-stepper-wrapper > .page-layout .outlet-player-navigation {
    padding-bottom: 50px;
    padding-top: 10px;
}

app-summary-sub-lesson {
    width: 100%;

    > div {
        padding: 0 10px 0 10px;
        display: flex;
        flex-direction: column;
    }

    .recap-header {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #743F90;
        margin-bottom: 12px;
    }

    .activity-counter {
        border: 1px solid #DFDFDF;
        box-shadow: 0px 9px 34px -12px rgba(0, 0, 0, 0.06);
        border-radius: 10px 10px 0px 0px;
        padding: 11px 16px 8px;
        margin: 20px auto 0;
        color: white;

        &.was-correct {
            background-color: #83DA0C;
        }

        &.incorrect {
            background-color: #565656;
        }
    }

    .mat-card {
        background-color: transparent;
        margin-bottom: 10px;
        padding: 0;
        box-shadow: 0 3px 20px -2px rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.1) !important;
        border-radius: 11px;

        .wording, .instruction {
            margin: 0;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0;
            text-align: center;
            color: #005D92;

        }

        mat-card-header.mat-card-header {
            border-radius: 12px 12px 0 0;
            background: #fff;
            border: 1px solid rgba(255, 255, 255, 0.9);
            padding: 20px 20px 0 20px;

            .mat-card-header-text, .mat-card-title {
                margin-bottom: 0;
                display: flex;

                app-consignes {
                    display: flex;
                    flex: 1 1 auto;
                    justify-content: center;
                }

                span.index {
                    margin: 0 5px 0 -5px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 19px;
                    letter-spacing: 0;
                    text-align: left;
                    color: #005D92;
                }

            }

            .head-marg-top {
                margin-bottom: 20px;
            }
        }

        mat-card-content.mat-card-content {
            background-color: #fff;
            padding: 0 20px 20px 20px;
            margin-bottom: 0;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0;
            text-align: center;
            color: #005D92;

            .multi-summary-container {
                padding: 10px;
                background: #E2F2FB;
                border: 1px solid #CBEAFB;
                box-sizing: border-box;
                text-align: left;
                display: flex;
                justify-content: center;
                align-content: center;
                flex-direction: column;
                align-items: center;
            }

            app-consignes, .guessing-item {
                @extend %textCartouche !optional;

                .wording, .instruction {
                    color: rgba(0, 93, 146, 1);

                    &.is-picture {
                        background: transparent !important;
                    }
                }

                &.is-picture {
                    flex-direction: column;
                    background: transparent !important;
                    padding: 0;
                    margin: 0 auto;
                }

                img {
                    border-radius: 10px;
                }
            }

            app-consignes > .container, app-consignes > .container > div {
                background-color: transparent;
                width: 100%;
            }
        }

        &.txtmat {
            mat-card-footer.mat-card-footer {
                > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .guessing-item {
                        @extend %textCartouche !optional;

                        &.is-picture {
                            background-color: inherit;
                            padding: 10px;

                            img {
                                max-height: 100px;
                                border-radius: 10px;
                            }
                        }
                    }

                    .answer-item img {
                        height: 70px;
                        width: 70px;
                        border-radius: 10px;
                        border: 4px solid #89E30C;
                    }
                }
            }
        }


        mat-card-footer.mat-card-footer {
            background: transparent;
            border: none;

            .answer-state {
                padding: 0;
                border-radius: 0 0 12px 12px;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 19px;
                letter-spacing: 0;
                width: 100%;

                > div {
                    padding: 20px 10px;
                    border-top: 1px solid rgba(0, 0, 0, 0.09);
                    width: 100%;
                }

                background-color: #F3FCE7;
                color: rgb(0, 93, 146);
            }

            .answer-item {
                padding: 10px 0;
            }

            .answer-position {
                @extend %answer-position;
                top: 105%;
                z-index: 100;
            }


        }
    }

    .footer-summary {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0 40px 0;

        > div {
            border: 1px solid rgba(0, 0, 0, 0.09)
        }

        button {
            @extend %button-image;
            @extend %button-big;
            @extend %button-image-big-purple;
            margin: 0 !important;


            .mat-button-wrapper {
                width: 100%;
            }

            .mat-icon {
                @extend %button-image-icon;
                order: 2;
                margin-left: auto;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    @extend %button-image-icon-svg;
                    width: 28px;
                    height: 28px;
                    min-width: 28px;
                    min-height: 28px;
                }
            }

            // padding: 5px 40px 6.2% 40px;

            > span {
                text-align: start;
            }


            &[disabled="true"], &.mat-raised-button[disabled][disabled] {
                color: white;
            }
        }
    }

    .app-order-matching #container-for-answers-selected {
        @extend %AnswersThumb;
        max-width: 100%;
        column-gap: 2%;

        > div {
            flex: 0 1 55px !important;
        }
    }

    .pointimg {
        &.mat-card mat-card-header.mat-card-header .head-marg-top {
            margin-bottom: 0;
        }
        .instruction.is-picture  {
            display: none;
        }

        .answer-wrapper{
            position: relative;
        }

        @extend %app-zone-generator;

        app-zone-generator {
            top: 0;
        }
    }

    .drl {
        &.mat-card {
            mat-card-footer.mat-card-footer .answer-state {
                border-top: 1px solid rgba(0, 0, 0, 0.09);
                display: flex;
                justify-content: center;
                .image-wrapper-drl {
                    border: none;
                    width: 40%;
                    overflow: hidden;
                    &::before {
                        content: '';
                        display: block;
                        width: 0;
                        height: auto;
                        padding-top: 100%;
                    }
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 100%;
                        height: 100%;
                        display: block;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }

}
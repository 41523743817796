@use '@angular/material' as mat;
$back-card-thumb-image-width: 260px;
$back-card-thumb-image-height: 278px;

 .cards { // TODO refactoriser en mat-card
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
 .card { // TODO refactoriser en mat-card
  padding: 24px 24px 16px;
}
 .fuse-card { // TODO refactoriser en mat-card
  min-height: 300px;
}
 .fuse-card,
 .fuse-card-header { // TODO refactoriser en mat-card
  border-radius: 10px 10px 0px 0px;
}
 .fuse-card-header { // TODO refactoriser en mat-card
  padding: 8px;
}
 .mat-card-header-text {
  display: flex;
  align-items: center;
}


.mat-card-actions,
.mat-card-content,
.mat-card-subtitle,
.mat-card-title {
  margin-bottom: 0;
}


 mat-card.mat-card {

    .card-cover{
        position:absolute; width: 100%; height: 100%; object-fit: cover;

        &.audio, &.url, &.document
        {
            width: 120px;
            height: 120px;
            left: 130px;
            top: 170px;
            transform: translate(-50%, -50%);
        }
        &.image, &.videoUrl
        {
            padding-top: 73px;
            object-fit: contain;
        }

    }

    &.card-simple, .card-simple & {
        background: unset;
        padding: 0; // reset mat

        .mat-card-header {
          padding: 16px;

          .mat-card-title {
            margin: 0;
          }
        }
        .mat-card-content {
            margin-bottom: 0;
        }
    }
}



// $color-header: map_get($md-navy, 500);
 .mat-card {

        .card-simple-course & {
            flex: 1 1 0%;
            box-sizing: border-box;
            flex-direction: column;
            display: flex;
            min-width: 100%;
            width: 100%;
            max-width: inherit;
            height: $back-card-thumb-image-height;
            margin-bottom: 20px;

            mat-card-header, mat-card-content, mat-card-actions {
                z-index: 1;
            }

            mat-card-header {
                flex: 1 1 100%; //1e-9px;
                box-sizing: border-box;
                display: flex;
                place-content: center flex-start;
                align-items: center;
                flex-direction: row;

                .pastille {
                    order: -1;
                    flex: 0 0 42px;
                    width: 42px;
                    height: 42px;
                    margin-right: 8px;
                }
                .mat-card-header-text {
                    margin: 0;
                    .mat-card-title {
                        font-weight: 500;
                        margin: 0;
                    }
                }
                button {
                    margin-left: auto;
                }
            }

            mat-card-content {
                display: flex;
                flex: 1 1 100%;
                flex-direction: column;
                overflow-y: auto;

                ul li {
                    margin-bottom: 13px;
                }
                .lesson-activity-infos {
                    margin-bottom: auto;

                    > * {
                        float: left;
                        width: 100%;
                    }

                    li:first-child {
                        margin-bottom: 0;
                    }
                }
                .lesson-label, .activity-label, .other-label {
                    padding-left: 5px;
                    dt::after {
                        content: " ";
                        display: inline;
                        padding-right: 0;
                        margin-left: 0;
                    }
                }
                .lesson-label, .activity-label{
                    &::after {
                        content:"-";
                        display: inline-block;
                        padding: 0 5px;
                    }
                }
                .lesson-name-label {
                    display: none;
                }
                .lesson-license-content {
                    font-style: italic;
                }
                .get-date-label dt {
                    display: none;
                    &::after {
                        content:":";
                        display: inline-block;
                        padding: 0 5px;
                    }
                }
                .get-date-label {
                    font-size: 12px;
                    margin-top: 13px;
                }
                .keywords {
                    margin: 0;
                    padding: 0;

                    > * {
                        display: inline-block;
                        color: white;
                        background: mat.get-color-from-palette($primary);
                        margin: 5px;
                        border-radius: 15px;
                        padding: 5px;
                        &::after {
                            display: none;
                        }
                    }
                }
                .dl-list {
                    margin: 0;
                    &.keywords-list {
                        margin-top: auto;
                    }
                }
                .metadata {
                    &:last-child {
                        margin-top: auto;
                    }
                }
                .meta-info {
                    display: inline-flex;
                    cursor: pointer;
                    color: white;
                    border-radius: 15px;
                    align-items: center;
                    padding: 4px 5px;
                    background-color: map-get(mat.$grey-palette, 300);
                    path {
                        fill: white;
                    }
                }
                .data-lam {
                    display: none;
                }
            }

            mat-card-actions {
                flex-direction: row;
                display: flex;
                place-content: center flex-start;
                align-items: center;

                .lesson-activity-infos {
                    font-style: italic;
                }
                .info-assignated {
                    flex-direction: row;
                    display: flex;
                    place-content: center flex-start;
                    align-items: center;
                    margin-right: 10px;

                    .mat-icon {
                        margin-right: 5px;
                        path {
                            fill: map-get(mat.$grey-palette, 300);
                        }
                        &.active, &:hover {
                            path {
                                fill: mat.get-color-from-palette($primary);
                            }
                        }
                    }

                    label {
                        cursor: pointer;
                    }
                }

                .button-favorite,
                .button-download-theme {
                    margin-left: auto;
                    box-shadow: none;
                    background: none;
                    color: rgba(0, 0, 0, 0.87);
                    .mat-icon {
                        margin-right: 5px;
                    }
                }
                .button-play {
                    position: absolute;
                    right: 25px;
                    bottom: 120px;
                }
            }
        }

        .card-split-media-info & {
            position: relative;
            padding: 0; // reset mat
            overflow: hidden;
            background: none;
            flex: 1 1 100%;
            flex-direction: column;
            box-sizing: border-box;
            display: flex;
            height: $back-card-thumb-image-height;

            &::after {
                content: "";
                display: block;
                background-color: white;
                width: calc(100% - #{$back-card-thumb-image-width});
                height: 100%;
                top: 0;
                left: $back-card-thumb-image-width;
                position: absolute;
                z-index: 0;
            }

            mat-card-header, mat-card-content, mat-card-actions {
                z-index: 1;
            }

            mat-card-header {
                color: white;
                display: flex;
                place-content: center flex-start;
                align-items: center;
                min-height: 52px;
                padding: 5px 11px;
                width: 100%;

                button {
                    margin-left: auto;
                }
                .pastille {
                    order: -1;
                    flex: 0 0 42px;
                    width: 42px;
                    height: 42px;
                    background-color: white;
                }
                .mat-card-subtitle, .mat-card-title {
                    margin: 0;
                }
            }

            img.mat-card-image {
                position: absolute;
                width: auto;
                height: 100%;
                margin: 0;
                top: 0;
            }
            .card-cover {
                max-width: 260px;
                max-height: 278px;
            }

            mat-card-content {
                background-color: white;
                margin-left: $back-card-thumb-image-width;
                padding: 18px 12px 0;
                margin-bottom: 0;
                min-height: 100px;
                overflow-y: auto;

                .lesson-activity-infos {
                    margin-bottom: auto;
                    > * {
                        float: left;
                        width: 100%;
                    }
                    li:first-child {
                        margin-bottom: 0;
                    }
                }

                .lesson-label, .activity-label, .other-label {

                    dt::after {
                        content: " ";
                        display: inline;
                        padding-right: 0;
                        margin-left: 0;
                    }
                }
            }

            mat-card-actions {
                background-color: white; // LAM
                margin: 0 0 0 $back-card-thumb-image-width;
                padding: 12px 16px;
                display: flex;
                place-content: center space-between;
                align-items: center;

                &.mat-card-actions:last-child {
                    margin-bottom: 0; //reset material
                    padding-bottom: 12px; //reset material
                    border: none; //reset material
                    margin-top: auto;
                }

                button {
                    &.button-favorite {
                        width: 40px;
                        height: 40px;
                        background: none;
                        min-width: auto;
                        box-shadow: none;
                        margin-left: auto;

                        .mat-button-wrapper span {
                            display: none;
                        }
                    }
                }

                .pastille {
                    width: 32px;
                    height: 32px;
                }
            }
        }

        .card-mini {

        }
}

 .card-mini {
    h2 {
        font-size: 20px;
        color: #757575;
        margin-bottom: 12px;
    }
    .mat-card {
        flex: 1 1 0%;
        box-sizing: border-box;
        flex-direction: column;
        display: flex;
        min-width: 100%;
        width: 100%;
        max-width: inherit;
        min-height: 200px;
        margin-bottom: 20px;
        mat-card-content {
            min-height: 150px;
            .label {
                font-size: 12px;
            }
            .value1 {
                font-size: 30px;
            }
            .label2, .value2
            {
                font-size: 10px;
            }
        }
    }
}

.difficultyStep{
    display: inline-block;
    height: 15px;
    width: 15px;
    background-color: #000000;
    border-radius: 15px;
    margin: 5px;
    vertical-align: middle;
    &.active{
        background-color: #5FD855;
    }
}

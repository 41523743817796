@use '@angular/material' as mat;
.filters {
    padding: 8px 0;
    margin: 0 8px 16px;


    [svgicon="search"] svg {

    }

    .mat-form-field-prefix, .mat-form-field-suffix {
        width: 24px;
        height: 24px;

        .mat-icon {
            position: absolute;
            top: 5px;

            svg {
                transform: scale(0.8);

                path {
                    fill: rgba(0, 0, 0, 0.38);
                }
            }
        }

    }


    .mat-form-field-infix {
        width: auto;
    }

    &:not(:root:root) { //HACK : only Safari
        fuse-app-lessons-list & {
            padding-top: 30px;
        }
    }

    form {
        width: 100%;
    }

    button {
        margin-bottom: 8px;
    }

    button:not(.mat-stroked-button) { // TODO remove it's like !important and it's specific design
        background-color: mat.get-color-from-palette($accent);
        color: #fff;

        path {
            fill: #fff;
        }

        svg.mat-datepicker-toggle-default-icon > path:first-child {
            fill: none;
        }

        &[disabled], &[disabled] {
            cursor: not-allowed;
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26);

            * {
                cursor: not-allowed;
            }
        }
    }

    .mat-toolbar-row, .mat-toolbar-single-row {
        height: auto;
        flex-wrap: wrap;

        .count-entities {
            margin-left: auto;
        }
    }

    &.mat-toolbar-multiple-rows {
        width: auto;
    }

    fuse-groups-list & {
        .mat-toolbar-row, .mat-toolbar-single-row {
            width: auto;
        }
    }

}

.filters-expand-wrapper {
    .mat-expansion-panel:not(.mat-expanded),
    .mat-expansion-panel.mat-expanded,
    {
        .mat-expansion-panel-header {
            padding: 0;
            min-height: 87px;

            .filters {
                flex: 1;
                margin: 0;
                padding: 15px 0 0 0;

                .mat-toolbar-row {
                    place-content: center space-between;
                    align-items: center;

                }

                .mat-icon {
                    transform: rotate(0); // reset orginal material icon rotate to custom icon theme display
                    svg path {
                        fill: none;
                    }
                }
            }

        }

        .mat-expansion-panel-body {
            padding: 0;
        }
    }
}

.filters {
    transition: max-height 1s ease;
    position: relative;
    overflow: hidden;
    flex: none;

    &.mat-toolbar-multiple-rows.isCollapsed {
        min-height: 75px;
    }



    &.isCollapsed {
        flex: none;
        max-height: 0;
        //transition: max-height 1s ease-out;
        transition: 1s ease-out;
        //transition: width 1s ease-out;
        top: 0;

        .collapsibleFields {
            -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            overflow: hidden;
            width: 0;
            height: 0;
            min-width: 0 !important;
            max-width: 0 !important;
            padding: 0 !important;
            margin: 0;
            display: none;





            @-webkit-keyframes fadeOut {
                0% {opacity: 1;}
                100% {opacity: 0;}
            }
            @keyframes fadeOut {
                0% {opacity: 1;}
                100% {opacity: 0;}
            }
        }
    }
    &.isExpanded {
        max-height: 500px;
        //transition: max-height 1s ease-out;
        transition: 1s ease-out;
        //transition: width 1s ease-out;
        flex: none;
        .collapsibleFields {
            -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;

            @-webkit-keyframes fadeIn {
                0% {opacity: 0; min-width: 0; width: 0;}
                100% {opacity: 1; min-width: 190px; width: inherit;}
            }
            @keyframes fadeIn {
                0% {opacity: 0; min-width: 0; width: 0;}
                100% {opacity: 1; min-width: 190px; width: inherit;}
            }
            //@-webkit-keyframes fadeIn {
            //    0% {opacity: 0;}
            //    100% {opacity: 1;}
            //}
            //@keyframes fadeIn {
            //    0% {opacity: 0;}
            //    100% {opacity: 1;}
            //}
        }
    }

    .btn-collapse {
        position: absolute;
        bottom: 10px;
        right: 10px;

        display: inline-flex;
        padding: 5px;
        flex-direction: row;
        align-items: center;
        background-color: transparent;
        color: mat.get-color-from-palette($accent);

        span{
        }
        .mat-icon {
            display: flex;
            border-radius: 50%;
            border: 1px solid mat.get-color-from-palette($accent);
            margin-left: 10px;
        }
        &:not(.mat-stroked-button) { // TODO remove html specific class design
            // move this property in .btn-collapse after TODO
            background-color: transparent;
            color: mat.get-color-from-palette($accent);
            path {
                fill: none
            }
        }
    }

}
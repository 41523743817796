%mat-tab-label {
    background-color: transparent;
    font-weight: 700;
    box-shadow: none !important;
    font-size: 18px;
}

.mat-tab-label {
    @extend %mat-tab-label;
}

.mat-ink-bar {

}

.mat-tab-group {
    flex: 1;
}